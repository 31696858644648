import React, { CSSProperties } from "react";
// Customizable Area Start
import { Grid, Box } from "@material-ui/core";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
const images = require("./assets");
import UserCommentController, {Props} from "./UserCommentController.web";

// Customizable Area End

// Customizable Area Start
interface WebStyle {
    [key: string]: CSSProperties;
}
// Customizable Area End

export default class UserComments extends UserCommentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
            const {commentsData } = this.state
        return (
            <Grid style={webStyle.setAllData}>
                {commentsData.map((data: any) => {
                    let commentCheck = data.attributes.comment
                    let commentText = commentCheck.reply? commentCheck.reply.content : commentCheck.comment.comment
                    return (
                        <Grid style={webStyle.showAllCommentsData}>
                            <Box style={webStyle.showComment}>
                                <img src={data.attributes.comment.catalogue_image} style={webStyle.catalogueImage} />
                                <Box style={webStyle.showUserComment}>
                                    <CustomTypography variant="secondary" component="emailText">You {commentCheck.reply ? "replied" : "commented"} on {data.attributes.comment.contributor_name} &nbsp; <img src={images.setDot} /> &nbsp; {this.handleTime(commentCheck.reply ? commentCheck.reply. updated_at :commentCheck.comment.updated_at  )} ago</CustomTypography>
                                    <CustomTypography variant="primaryDark" component="body12">{commentText}</CustomTypography>
                                </Box>
                            </Box>
                            <img src={images.arrow} style={webStyle.arrowBtn} />
                        </Grid>
                    )
                })}
            </Grid>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
const webStyle: WebStyle = {
    showAllCommentsData: {
        width: "95%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        display: 'flex',
        justifyContent: "space-between",
        padding: "20px"
    },
    catalogueImage: {
        width: "85px",
        height: "85px"
    },
    showComment: {
        display: "flex",
        gap: '10px',
        paddingLeft: "10px"
    },
    showUserComment: {
        display: 'flex',
        flexDirection: "column",
        gap: "5px",
        paddingTop: "5px"
    },
    arrowBtn: {
        width: "38px",
        height: "38px"
    },
    setAllData: {
        display: "flex",
        gap: "30px",
        flexDirection: "column",
        padding:"0px 30px"
    }
};
// Customizable Area End
