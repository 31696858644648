import React, { CSSProperties } from "react";
// Customizable Area Start
import { Divider, Grid } from "@material-ui/core";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
const images = require("./assets");
import ModalComponent from "../../../components/src/DesignSystem/Modalcomponent/ModalComponent.web";
import CloseIcon from '@material-ui/icons/Close';
import UserHiresDownloadController, { Props } from './UserHiresDownloadController.web';
// Customizable Area End

// Customizable Area Start
interface WebStyle {
    [key: string]: CSSProperties;
}
// Customizable Area End

export default class UserDownloadableProduct extends UserHiresDownloadController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
    const { fromPage} = this.props;
    const {openInvoiceModal} = this.state;
    // Customizable Area Start
    const setArray = ["1", "2", "3"];
    return (
        <>
            <Grid style={webStyle.showAllDownload}>
                {setArray.map((data: string, index: number) => {
                    return (
                        <Grid
                            style={webStyle.dowloadPreviewData}
                            key={index}
                            data-test-id="downloadData"
                        >
                            <Grid style={webStyle.detailedShow}>
                                <Grid>
                                    <img src={images.Flower} />
                                </Grid>
                                <Grid style={webStyle.contentDetails}>
                                    {fromPage === "PurchaseHistory" &&
                                        <CustomTypography component="body15">Purchased on 22 sep, 2023</CustomTypography>
                                    }
                                    <CustomTypography component="body11500">
                                        Mountains, Landscape beautiful nature image. The perfect
                                        mountain arial shot.
                                    </CustomTypography>
                                    <CustomTypography component="body15">
                                        by @John Leones
                                    </CustomTypography>
                                    <Grid style={webStyle.detailedBtn}>
                                        <CustomButton
                                            variant={"downloadPreviewBtn"}
                                            size={"smallBtns"}
                                        >
                                            16:9 Landscape
                                        </CustomButton>
                                        <CustomButton
                                            variant={"downloadPreviewBtn"}
                                            size={"smallBtns"}
                                        >
                                            5641 x 3261
                                        </CustomButton>
                                        <CustomButton
                                            variant={"downloadPreviewBtn"}
                                            size={"smallBtns"}
                                        >
                                            Digital
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {fromPage === "PurchaseHistory" ? <Grid style={{ ...webStyle.downloadPreviewSection, flexDirection: "column", gap: '20px' }}>
                                <CustomButton
                                    variant={"primary"}
                                    size={"smallBtn"}
                                >
                                    Download License
                                </CustomButton>
                                <Grid style={webStyle.viewText} data-test-id="handleInvoice" onClick={this.handleInvoice}>
                                    <CustomTypography variant="body" component="blue">View Invoice</CustomTypography>
                                </Grid>
                            </Grid> :
                                <Grid style={webStyle.downloadPreviewSection}>
                                    <CustomButton
                                        variant={"downloadPreviewBtn"}
                                        size={"smallBtn"}
                                    >
                                        Download Preview
                                    </CustomButton>
                                </Grid>
                            }
                        </Grid>
                    );
                })}
                <ModalComponent
                    open={openInvoiceModal}
                    maxWidth={714}
                    maxHeight={828}
                    modalMinHeight={'0px'}
                >
                    <Grid style={webStyle.mainModalBox}>
                        <div style={webStyle.crossIcons}>
                            <CustomTypography component="body6">Invoice</CustomTypography>
                            <CloseIcon onClick={this.handleCloseInvoice} style={webStyle.cursor} />
                        </div>
                        <Grid style={webStyle.showInvoiceContent}>
                            <CustomTypography variant="primary" component="accountText">Billing details</CustomTypography>
                            <Divider style={webStyle.dividerGap}/>
                            <Grid style={webStyle.showBilling}>
                                <Grid style={webStyle.showContent}>
                                    <Grid>
                                        <CustomTypography variant="body21" component="secondary">Invoice number</CustomTypography>
                                        <CustomTypography variant="primary" component="body21">INV-2023-001</CustomTypography>
                                    </Grid>
                                    <Grid>
                                        <CustomTypography variant="body21" component="secondary">Purchase date</CustomTypography>
                                        <CustomTypography variant="primary" component="body21">26/09/2023</CustomTypography>
                                    </Grid>
                                </Grid>
                                <Grid style={webStyle.showAddress}>
                                    <CustomTypography variant="body21" component="secondary">Billing address</CustomTypography>
                                    <CustomTypography variant="primary" component="body21">John Doe 1234, 5th Main Road Indiranagar Banglore, Karnataka 560001, India</CustomTypography>
                                </Grid>
                            </Grid>
                            <CustomTypography variant="primary" component="accountText">Product details</CustomTypography>
                            <Divider style={webStyle.dividerGap}/>
                            <Grid style={webStyle.showBilling}>
                                <Grid style={webStyle.showContent}>
                                    <Grid>
                                        <CustomTypography variant="body21" component="secondary">Stock id</CustomTypography>
                                        <CustomTypography variant="primary" component="body116">1057927591</CustomTypography>
                                    </Grid>
                                    <Grid style={webStyle.typeShow}>
                                        <CustomTypography variant="body21" component="secondary">Type</CustomTypography>
                                        <CustomTypography variant="primary" component="body116">Image</CustomTypography>
                                    </Grid>
                                </Grid>
                                <Grid style={webStyle.showContent}>
                                    <Grid>
                                        <CustomTypography variant="body21" component="secondary">Resolution</CustomTypography>
                                        <CustomTypography variant="primary" component="body116">16:9 Landscape</CustomTypography>
                                    </Grid>
                                    <Grid>
                                        <CustomTypography variant="body21" component="secondary">Size</CustomTypography>
                                        <CustomTypography variant="primary" component="body116">16:9 Landscape</CustomTypography>
                                    </Grid>
                                    <Grid>
                                        <CustomTypography variant="body21" component="secondary">Distribution purpose</CustomTypography>
                                        <CustomTypography variant="primary" component="body116">Digital/web</CustomTypography></Grid>
                                </Grid>
                            </Grid>
                            <CustomTypography variant="primary" component="accountText">Payment details</CustomTypography>
                            <Divider style={webStyle.dividerGap}/>
                            <Grid style={webStyle.showBilling}>
                                <Grid style={webStyle.showContent}>
                                    <Grid><CustomTypography variant="body21" component="secondary">Total price</CustomTypography>
                                        <CustomTypography variant="primary" component="body21">72 AED</CustomTypography></Grid>
                                    <Grid style={webStyle.dateShow}><CustomTypography variant="body21" component="secondary">payment Date</CustomTypography>
                                        <CustomTypography variant="primary" component="body21">26/09/2023</CustomTypography></Grid>
                                </Grid>
                                <Grid style={webStyle.showContent}>
                                    <Grid><CustomTypography variant="body21" component="secondary">Payment status</CustomTypography>
                                        <CustomTypography variant="primary" component="body21">Paid</CustomTypography></Grid>
                                    <Grid><CustomTypography variant="body21" component="secondary">Payment method</CustomTypography>
                                        <CustomTypography variant="primary" component="body21">Card</CustomTypography></Grid>
                                </Grid>
                            </Grid>
                            <Grid style={webStyle.showInvoiceBtn}>
                                <Grid style={webStyle.btnWidth}>
                                <CustomButton variant={"downloadPreviewBtn"}
                                        size={"LargeJoinBTns"} fullWidth="fullWidth">Cancel</CustomButton>
                                </Grid>
                                <Grid style={webStyle.btnWidth}>
                                    <CustomButton  startIcon={<img src= {images.downloadInvoice}/>} size={"LargeJoinBTns"} fullWidth="fullWidth">Download Invoice</CustomButton>
                                </Grid></Grid>
                        </Grid>
                    </Grid>
                </ModalComponent>
            </Grid>
        </>
    );
            }
    // Customizable Area End
}
// Customizable Area Start
const webStyle: WebStyle = {
    dowloadPreviewData: {
        display: "flex",
        justifyContent: "space-between",
        width: "95%",
        padding: "20px",
        height: "100%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
    },
    detailedBtn: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px"
    },
    contentDetails: {
        height: "80%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    downloadPreviewSection: {
        display: "flex",
        alignItems: "center",
        paddingRight: "50px",
    },
    detailedShow: {
        display: "flex",
        gap: "30px",
        paddingLeft:"50px"
    },
    showAllDownload: {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    viewText: {
        cursor: "pointer"
    },
    mainModalBox: {
        overflowX: "hidden"
    },
    crossIcons: {
        display: "flex",
        justifyContent: "right",
        gap: "42%",
        overflowX: "hidden"
    },
    cursor: {
        cursor: 'pointer'
    },
    showInvoiceContent: {
        padding: "5%",
    },
    showBilling: {
        padding: "25 0px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    showContent: {
        display: "flex",
        gap: "20%"
    },
    typeShow: {
        paddingLeft: "25px"
    },
    dateShow: {
        paddingLeft: "30px"
    },
    showInvoiceBtn:{
        display:'flex',
        width:'100%',
        gap:'20px'
    },
    btnWidth:{
        width:'100%'
    },
    dividerGap:{
        marginTop:"5px"
    },
    showAddress:{
        width:"60%"
    }
};
// Customizable Area End
