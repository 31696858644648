import React from "react";
// Customizable Area Start
import MyreferralsController, { Props } from "./MyreferralsController";
import { Box, Grid, styled } from "@material-ui/core";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  sideBarContributorListItem,
  sideBarContributoBottomNav,
} from "../../../blocks/navigationmenu/src/NavigationMenuController";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import { facebook, linkdin, myreferralsbanner, twitter } from "./assets";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
import MyreferralsSummary from "./MyreferralsSummary.web";
import MyreferralsHowitworks from "./MyreferralsHowitworks.web";
import MyreferralsReferredcontributors from "./MyreferralsReferredcontributors.web";
import { configJSON } from "./Paymentadmin2Controller";
import MyreferralsTermsandconditions from "./MyreferralsTermsandconditions.web";
// Customizable Area End

class Myreferrals extends MyreferralsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu
          navigation={this.props.navigation}
          id={""}
          contributorLogin={true}
          data-test-id="showCollection"
        />
        <SideBar
          id=""
          navigation={this.props.navigation}
          sideBarBottomNav={sideBarContributoBottomNav}
          sideBarListItem={sideBarContributorListItem}
        >
          <CustonContainer>
            <Heading>
              <CustomTypography component="h7">My Referrals</CustomTypography>
            </Heading>
            <TopBanner>
              <CustomTypography variant="white" component="title2">
                Earn more by referring contributors and customers
              </CustomTypography>
              <CustomTypography variant="white" component="body22">
                Earn $0.04 on images and 10% on videos when an artist signs up
                with your link and their work is downloaded.
              </CustomTypography>
              <CopyBox>
                <CustomTypography variant="secondaryLight" component="body22">
                  https://submit.shutterstock.com/?rid=406772209
                </CustomTypography>
                <CustomButton variant="tertiaryWithoutBorderBlue">
                  Copy
                </CustomButton>
              </CopyBox>
              <SocialIcon>
                <img src={facebook} alt="icon" />
                <img src={twitter} alt="icon" />
                <img src={linkdin} alt="icon" />
              </SocialIcon>
            </TopBanner>
            <Heading1>
              <CustomTypography component="h7">Summary</CustomTypography>
            </Heading1>
            <Grid container spacing={2} style={webStyles.gridContainer}>
              <Grid item xs={12} lg={6} style={webStyles.gridTopSpacing}>
                <MyreferralsSummary
                  heading="Total referral earnings"
                  subHeading="$0.00"
                  ContributorCount="0"
                  CustomerCount="0"
                />
              </Grid>
              <Grid item xs={12} lg={6} style={webStyles.gridTopSpacing}>
                <MyreferralsSummary
                  heading="Total referrals"
                  subHeading="0"
                  ContributorCount="0"
                  CustomerCount="0"
                />
              </Grid>
              <Heading2>
                <CustomTypography component="h7">How it works</CustomTypography>
              </Heading2>
              <Grid item xs={12} lg={6} style={webStyles.gridTopSpacing}>
                <MyreferralsHowitworks
                  heading="Invite fellow artists"
                  subHeading="Earn $0.04 on images and 10% on videos when an artist signs up with your link and their work is downloaded"
                  copyUrl="https://submit.shutterstock.com/?rid=406772209"
                />
              </Grid>
              <Grid item xs={12} lg={6} style={webStyles.gridTopSpacing}>
                <MyreferralsHowitworks
                  heading="Share your work with buyers"
                  subHeading="Earn 20%, up to $200, on a customer's first payment when they use your link to sign up."
                  copyUrl="https://submit.shutterstock.com/?rid=406772209"
                />
              </Grid>
              <Heading2>
                <CustomTypography component="h7">
                  Referred contributors
                </CustomTypography>
              </Heading2>
              <Grid item xs={12} lg={12} style={webStyles.gridTopSpacing}>
                <MyreferralsReferredcontributors
                  handingListData={configJSON.handingContributorData}
                  title={"Start referring contributors today"}
                />
              </Grid>
              <Heading2>
                <CustomTypography component="h7">
                  Customer referral earnings
                </CustomTypography>
              </Heading2>
              <Grid item xs={12} lg={12} style={webStyles.gridTopSpacing}>
                <MyreferralsReferredcontributors
                  handingListData={configJSON.handingCustomerData}
                  title={"Start referring customers today"}
                />
              </Grid>
              <Heading2>
                <CustomTypography component="h7">
                  Terms and conditions
                </CustomTypography>
              </Heading2>
              <Grid item xs={12} lg={12} style={webStyles.gridTopSpacing}>
                <MainTerm>
                  <MyreferralsTermsandconditions
                    data-test-id="handleExpand"
                    expanded={this.state.expandContributors}
                    handleExpand={this.handleExpandContributors}
                    title={"Referring Contributors"}
                    content={
                      "Earn more by referring contributors and customers"
                    }
                  />
                  <MyreferralsTermsandconditions
                    expanded={this.state.expandCustomers}
                    handleExpand={this.handleExpandCustomers}
                    title={"Referring Customers"}
                    content={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    }
                  />
                  <MyreferralsTermsandconditions
                    expanded={this.state.expandPayments}
                    handleExpand={this.handleExpandPayments}
                    title={"Delivery of Payments"}
                    content={
                      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    }
                  />
                </MainTerm>
              </Grid>
            </Grid>
          </CustonContainer>
          <Footer userType={"contributor"} navigation={""} />
        </SideBar>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyles: any = {};

const CustonContainer = styled(Box)({
  paddingTop: "1rem",
  marginLeft: "1rem",
  width: "calc(100% - 2.5rem)",
});

const TopBanner = styled(Box)({
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${myreferralsbanner})`,
  backgroundSize:"cover",
  backgroundPosition:"100% 100%",
  padding: "32px 24px 1px 24px",
  "& > div": {
    marginBottom: "32px",
  },
});

const CopyBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: customTheme.palette.white.main,
  height: 56,
  maxWidth: "597px",
  padding: "0px 10px 0px 16px",
});

const SocialIcon = styled(Box)({
  display: "flex",
  "& > img": {
    paddingRight: "20px",
  },
});

const Heading = styled("div")({
  marginBottom: 32,
});

const Heading1 = styled("div")({
  margin: "32px 0px 20px 0px",
  width: "100%",
});

const Heading2 = styled("div")({
  margin: "32px 0px 20px 10px",
  width: "100%",
});

const MainTerm = styled("div")({
  marginBottom: 30,
});

// Customizable Area End

export default Myreferrals;
