import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { getStorageData} from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Array{
}
interface S {
  // Customizable Area Start
  applyClick:boolean;
  selectedFile:any;
  rotation:any;
    size: { width: number; height: number };
  updatedImage:any;
  errorMess:boolean;
  UpdatedWatermarkImage:any;
  fileUploadedMess:boolean;
  showSnackbar:boolean;
  FileUploadName:string;
  FileUploadType:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WatermarkController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    public inputRef:any
    public imageRef:any
    postWatermarkApiCall: string = '';
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
      applyClick:false,
      selectedFile:null,
      rotation:0,
      size:{
        width:200,
        height:200,
      },
      updatedImage:null,
      errorMess:false,
      UpdatedWatermarkImage:null,
      showSnackbar:false,
      fileUploadedMess:false,
      FileUploadName:"",
      FileUploadType:"",
    };
    this.inputRef=React.createRef();
    this.imageRef=React.createRef();
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        if (apiRequestCallId === this.postWatermarkApiCall) {
          this.setState({showSnackbar:true})
            if (!responseJson.errors) {
                this.setState({ fileUploadedMess: true });
            }else{
              this.setState({ fileUploadedMess: false });
            }
        }
    } else {
        this.parseApiErrorResponse(errorReponse);
    }    
    }
    // Customizable Area End
  }

  // Customizable Area Start


  isValidImage = (file: File) => {
    const FileRegexCheck=/\.(pg|jpeg|png|gif|bmp|eps|jpg)$/i;
    if(FileRegexCheck.test(file.name)){
       return file ;
    }else{
      this.setState({errorMess:true})
    }
  };
  postWatermarkApi = async () => {
    const token = await getStorageData("authToken");
    const header = {
     "token": token, 
    };
  
    const data = new FormData();
     data.append("image",this.state.UpdatedWatermarkImage);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postWatermarkApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWaterMarkImageEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.watermarkPostAPiMethod
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.postWatermarkApiCall;
  };
  
  handleFiles=(File:any)=>{
    const file=File[0];
     if(this.isValidImage(file) && file.type.startsWith('image/')){
        this.setState({selectedFile:URL.createObjectURL(file),FileUploadName:file.name,FileUploadType:file.type})  
     }
  }  
    handleFileInputChange = (event: any) => {
      const selectFile=event.files;
    this.handleFiles(selectFile);
  };
  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.handleFiles(files);
  };
 
  handleApplyClick = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      canvas.width = this.state.size.width;
      canvas.height = this.state.size.height;
      context.translate(this.state.size.width / 2, this.state.size.height / 2);
      context.rotate((this.state.rotation * Math.PI) / 180);
      context.drawImage(
        this.imageRef.current as HTMLImageElement,
        -this.state.size.width / 2,
        -this.state.size.height / 2,
        this.state.size.width,
        this.state.size.height
      );
      this.setState({ updatedImage: canvas.toDataURL() });
    }
    this.setState({ applyClick: true });
  };
  handleUploadClick=()=>{
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  }
  handleRightRotation=()=>{
   this.setState({rotation:this.state.rotation + 90}) 
  }
  handleLeftRotation=()=>{
    this.setState({rotation:this.state.rotation - 90}) 
  }
  handleResize = (event:any, { size }:any) => {
    this.setState({size});
  };
  handleInValidContent=()=>{
    this.setState({errorMess:false})
  }
  handleSubmit=()=>{
    this.handleEncodedUrl();
  }
  handleEditCancel=()=>{
    this.setState({
      rotation: 0,
      size: {
        width: 200, 
        height: 200,
      },
    });
  }
  handleCloseSnackBar=()=>{
    this.setState({showSnackbar:false})
  }
  handleSubmitCancel=()=>{
    this.setState({selectedFile:null,updatedImage:null,applyClick:false})
  }
  handleEncodedUrl=()=>{
    const base64Data = this.state.updatedImage.split(',')[1];
    const binaryData = Buffer.from(base64Data, 'base64').toString('binary');
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const filename = this.state.FileUploadName;
    const mimeType = this.state.FileUploadType;
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const file = new File([blob], filename, { type: mimeType });
      this.setState({UpdatedWatermarkImage:file},() => {
        this.postWatermarkApi();
      })
  }  
  
  // Customizable Area End
}
