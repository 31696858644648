import React from "react";
// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomCard from "../../../components/src/DesignSystem/CustomCard/CustomCard.web";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import { facebookgrey, linkdingrey, twittergrey } from "./assets";

interface Props {
  heading: any;
  subHeading: any;
  copyUrl: any;
}
// Customizable Area End

class MyreferralsHowitworks extends React.Component<Props> {
  render() {
    // Customizable Area Start
    return (
      <CustomCard variant="variant3">
        <Main>
          <CustomTypography component="header2">
            {this.props.heading}
          </CustomTypography>
          <CustomTypography component="body22">
            {this.props.subHeading}
          </CustomTypography>
          <CopyBox>
            <CustomTypography variant="secondaryLight" component="body22">
              {this.props.copyUrl}
            </CustomTypography>
            <CustomButton variant="tertiaryWithoutBorderBlue">
              Copy
            </CustomButton>
          </CopyBox>
          <SocialIcon>
            <img src={facebookgrey} alt="icon" />
            <img src={twittergrey} alt="icon" />
            <img src={linkdingrey} alt="icon" />
          </SocialIcon>
        </Main>
      </CustomCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles: any = {};

const Main = styled("div")({
  padding: "16px 16px 4px 16px",
  height:"100%",
  "& > div": {
    marginBottom: "24px",
  },
});

const CopyBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: customTheme.palette.white.main,
  height: 56,
  maxWidth: "597px",
  padding: "0px 10px 0px 16px",
  border: `1px solid ${customTheme.palette.secondary.main}`,
});

const SocialIcon = styled("div")({
  display: "flex",
  "& > img": {
    paddingRight: "20px",
  },
});

// Customizable Area End

export default MyreferralsHowitworks;
