import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  active: number;
  selectedMonth: string;
  selectedYear: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyreferralsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      active: 1,
      selectedMonth: "October",
      selectedYear: "2023",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  tabsData = [
    {
      id: 1,
      tab: "By Month",
    },
    {
      id: 2,
      tab: "By Product",
    },
    {
      id: 3,
      tab: "Adjustments",
    },
  ];
  handleActive = (id: number) => {
    this.setState({ active: id });
  };
  handleMonth = (option: { value: string; label: string }) => {
    this.setState({ selectedMonth: option.value });
  };
  handleYear = (option: { value: string; label: string }) => {
    this.setState({ selectedYear: option.value });
  };
  // Customizable Area End
}
