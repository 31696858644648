import React from "react";
// Customizable Area Start
import CustomAccordion from "../../../components/src/DesignSystem/CustomAccordion/CustomAccordion.web";
import { styled } from "@material-ui/core";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";

interface Props {
  title: any;
  content: any;
  expanded: any;
  handleExpand: any;
}
// Customizable Area End

class MyreferralsTermsandconditions extends React.Component<Props> {
  render() {
    // Customizable Area Start
    return (
      <>
        <Main>
          <CustomAccordion
            expanded={this.props.expanded}
            handleExpand={this.props.handleExpand}
            title={this.props.title}
            content={this.props.content}
            AccordionDetailsVariant={"body11"}
            AccordionSummaryVariant={"body11"}
          />
        </Main>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles: any = {};

const Main = styled("div")({
  borderBottom: `1px solid ${customTheme.palette.secondary.light}`,
  marginBottom:"16px",
  "& .MuiPaper-elevation1 ": {
    boxShadow: "unset",
  },
  "& .CustomAccordion-accordion-47 ": {
    marginBottom:"unset"
  },
  "& .MuiAccordionSummary-content":{
    margin:"unset"
  },
  ".MuiAccordionSummary-root":{
    marginBottom:"-25px"
  }
});

// Customizable Area End

export default MyreferralsTermsandconditions;
