Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PhotoLibrary";
exports.labelBodyText = "PhotoLibrary Body";
exports.errorMessage = "Something went wrong!";
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.collection= "collections";
exports.newCollection = "new collection";
exports.photoLibraryApiEndpoint = "photo_library/photo_library";
exports.contentType = [
  {label:"Images",value:'image'},
   {label:"videos",value:"video"},
   ];
exports.publishType = [
    {label:"Published",value:'public_domain'},
     {label:"Unpublished",value:"private_domain"},
     ];
exports.sortType = [
  {label:"Sort By",value:'Sort By'},
  {label:"Last Updated",value:'last_updated'},
   {label:"Newest First",value:"newest_first"},
   {label:"Oldest First",value:"oldest_first"},

   ];
exports.searchText = "Search by collection ID, keyword, collection title";
exports.noContentFound = "No Approved Catalogue Found";
exports.createCollectionText = "create new collections and track your earnings.";
exports.viewContentText = "You can view your content's performance and stay organized."
exports.collectionBtn = "create a collection";
exports.collectionName="Collection Name";
exports.save="Save";
exports.noCollectionFound= "No Collection Found";
exports.cancelBtnLabel= "Cancel";
exports.deleteBtnLabel = "Yes, I Confirm";
exports.deleteCollectionContent = "You want to delete this Collection, Please Confirm";
exports.deleteContent = "You want to delete this Content, Please Confirm";
exports.deleteDialogTitle = "ARE YOU SURE?"
exports.collectionType = "Collection Type";
exports.image="Image";
exports.video = "Video";
exports.publicVisibility = "Public";
exports.privateVisibility = "Private";
exports.addAssets = "Add Assets";
exports.noAssetsText = "You Haven't Added any assets yet";
exports.or="or";
exports.newCollectionName = " New Collection Name";
exports.existingCollectionName = "Existing Collection";
exports.postMethod="POST";
exports.getMethod= "GET";
exports.putMethod="PUT";
exports.deleteMethod = "DELETE";
exports.chooseCollection="Nature_image 2023";
exports.showCollectionEndPoint=(selectedContentLabel,selectedPublishLabel,selectedUpdateLabel,currentPage, perPage,searchValue) => `bx_block_photo_library/collections?collection_type=${selectedContentLabel}&collection_subtype=${selectedPublishLabel}&sort_by=${selectedUpdateLabel}&page=${currentPage}&per_page=${perPage}&search=${searchValue}`;
exports.createCollectionEndPoint="bx_block_photo_library/collections";
exports.collectionEndPoint=(collectionId) => `bx_block_photo_library/collections/${collectionId}`;
exports.showApprovedEndPoint = "bx_block_catalogue/catalogues/sort_via_reviewed?sort=accepted";
exports.createGalleryEndPoint = "bx_block_photo_library/galleries";
exports.selectedCollectionEndPoint =(collectionId,currentDataPage,perDataPage,selectedUpdateLabel)=> `bx_block_photo_library/collections/${collectionId}?page=${currentDataPage}&per_page=${perDataPage}&sort_by=${selectedUpdateLabel}`;
exports.galleryDeleteEndPoint = (galleryId, catalogueId) => `bx_block_photo_library/galleries/${galleryId}?catalogue_ids=${catalogueId}`;
exports.downloadCatalogueEndPoint = (collectionId, galleryId, catalogueId) => `bx_block_photo_library/collections/${collectionId}/download_catalogues?gallery_id=${galleryId}&catalogue_ids=${catalogueId}`
// Customizable Area End

exports.textDelete = "Delete";
exports.textDeleteSelected = "Delete Selected";
exports.textDeleteGallery = "Delete Gallery";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textSelectImage = "Select Image";
exports.textAddNewImage = "Add New Image";
exports.textShowImages = "Show Images";
exports.noImageErrorMsg = "Please select an image";
exports.textShare = "Share";
exports.textAccountId = "Account ID";
exports.errorMsgAccountId = "Account ID cannot be empty";
