import React from "react";
// Customizable Area Start
import Header from "../../../components/src/DesignSystem/Header/Header.web";
import {
  Box,
  Grid,
  Tabs,
  Tab,
  styled
} from "@material-ui/core";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import UserActivityController,{Props, TabType} from "./UserActivityController";
import customTheme from "../../../../packages/components/src/DesignSystem/Theme/Theme.web";
import SearchResults from "../../../components/src/DesignSystem/SearchResult/SearchResults.web";
import UserDownloadableProduct from "./UserDownloadableProduct.web";
import UserComments from "./UserComments.web";
import UserHires from "./UserHires.web";
import UserFollowing from "./UserFollowing.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class UserActivity extends UserActivityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { selectedId} = this.state;
    return (
      <>
          <Header testID={""} navigation={this.props.navigation} classNameProps={""} />
          <Main>
            <div style={webStyle.breadCrumb}>
              <div test-Id="profileBtn" onClick={()=> this.goToProfileTab()} style={webStyle.profileTab}>
              <CustomTypography variant='secondary' component='body11bold'>Profile / &nbsp;</CustomTypography>
              </div>
              <CustomTypography component='body11bold'>{this.state.selectedLabel}</CustomTypography>
            </div>
          <Container container>
            <div style={webStyle.justifyCenter}>
            <TabS
              value={selectedId}
              data-test-id="handleSelect"
              onChange={(e,value)=>this.handleChange(e,value)}
              id="tabs"
              style={webStyle.justifyCenter}
            >
            {this.state.tabsMenu.map((tabs:TabType) => (
                <Tab
                  value={tabs.id}
                  label={tabs.label}
                  style={{...webStyle.tabStyle,textTransform:"none"}}
                />
                ))}
            </TabS>
            </div>
            {selectedId == 1 && <UserFollowing navigation={undefined} id={""}/>}
            {selectedId == 2 && <UserDownloadableProduct fromPage="PurchaseHistory" navigation={undefined} id={""}/>}
            {selectedId == 3 && <UserComments navigation={undefined} id={""}/>}
            {selectedId == 4 && 
            <div style={webStyle.favouriteDiv}>
            <SearchResults 
              testID={"resultsCollection"}
              navigation={this.props.navigation}
              data={this.state.bookmarkData} 
              searchQuery={""}
              fromPage={"Favourites"}    
              onBookmarkClick={(e:any)=>{this.handleCollection(e)}}  
              onDownloadBtnClick={(e:any) => this.handleDownloadBtn(e) }         
            />
            </div>
            }
            {selectedId == 5 &&  <div style={webStyle.favouriteDiv}>
            <SearchResults 
              testID={"results"}
              navigation={this.props.navigation}
              data={this.state.collectionData} 
              searchQuery={""}
              fromPage={"MyCollection"}   
              isLoading={this.state.isLoading}    
              onBookmarkClick={()=>{}}  
              onDownloadBtnClick={(e:any) => this.handleDownloadBtn(e) }         
            />
            </div>
             }
            {selectedId == 6 && <UserDownloadableProduct fromPage="Download" navigation={undefined} id={""}/>}
            {selectedId == 7 && <UserHires navigation={undefined} id={""}/>}
          </Container>
          </Main>
          <Footer 
            userType={"normal"} 
            navigation={this.props.navigation}
          />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  breadCrumb:{
    display:"flex",
    marginBottom: 30
  },
  profileTab:{
    cursor:"pointer"
  },
  tabStyle: {
    fontSize:16,
    fontFamily: 'Outfit',
    borderBottom: "2px solid #e8e8e8",
  },
  justifyCenter:{
    display:"flex",
    justifyContent:"center"
  },
  favouriteDiv:{
    width:"100%"
  }
};
const Main = styled(Box)({
  padding: 32,
  backgroundColor: customTheme.palette.grey.secondary
});

const Container = styled(Grid)({
  padding:24,
  flexDirection:"column",
  gap:20,
  backgroundColor: customTheme.palette.white.main,
});
const TabS = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "black",
    height: "3px" 
  },
  "& .PrivateTabIndicator-colorSecondary": {
    backgroundColor: "black",
    height: "3px"
  }
});
// Customizable Area End
