import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Box,
  Button,
  styled,
  Avatar,
  Select, MenuItem, Tabs, Tab
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { language, logo,profile } from "./assets";
import CustomLink from '../Link/Link.web';
import CustomModal from '../CustomModal/CustomModal.web';
import EmailAccountLoginBlock from '../../../../blocks/email-account-login/src/EmailAccountLoginBlock';
import EmailAccountRegistartion from '../../../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import CustomTypography from '../CustomTypography/CustomTypography.web';
import CustomDropDown from "../CustomDropDown/CustomDropDown.web";
import LanguageIcon from '@material-ui/icons/Language';
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import CustomButton from '../CustomButton/CustomButton.web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'white',
    height: 72,
    boxShadow: 'none!important',
    borderBottom: "1px solid #C4C4C4"
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuIcon: {
    color: '#000'
  },
  menuWrapperlg: {
    width: '100%',
    height: 72,
    display: 'flex',
    marginTop: '5px',
    marginLeft: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
  },
  menuDropDown:{
    width: '100%',
    height: 72,
    display: 'flex',
    marginTop: '5px',
    marginLeft: '20px',
    justifyContent:"flex-end",
    color:"black",
    "& > div":{
      width:"172px",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
    }
  },
  menuListWrap: {
    height: 72,
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    width: '108px',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
  },
  displayFlex: {
    display:"flex",
    "& > span":{
      display:"flex",
      alignItems:"center",
      marginTop:"6px"
    }
  },
  rightContainer:{
    width: '100%',
    height: 72,
    display: 'flex',
    marginTop: '5px',
    // marginLeft: '20px',
    justifyContent:"flex-end",
    color:"black",
    alignItems:"center",
    gap:"20px"
  },
  leftContainer:{
    width: '100%',
    height: 72,
    display: 'flex',
    marginTop: '5px',
    marginLeft: '20px',
    color:"black",
    alignItems:"center",
    gap:"20px"
  },
  btnContainer:{
    width:"8.25rem"
  },
  portfolioTab:{
    color:"black",
    fontWeight:400
  }
}));

const ProfileButton=styled(Button)({
  minWidth:0
})
const LanguageSelector= styled(Button)({
  minWidth:0
})
const SelectInput = styled(Select)({
  outline: "none",
  border: "none",
  margin: "unset",
  paddingLeft: "10px",
  "& .MuiInput-underline::before": {
    borderBottom: "none", 
  },
  "@media (hover: none)": {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },}
});
const Header = (props: any) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [closeModalProp, setCloseModalProp] = useState<any>();
  const [closeLoginModal, setCloseLoginModal] = useState<any>();
  const [closeSignupModal, setCloseSignupModal] = useState<any>();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(props.contributorLogin);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [contributorName, setContributorName]  =useState ('');
  const [selectedProfile, setSelectedProfile] = useState('')
  const changeModalProp = () => {
    setCloseModalProp(false)
    setIsUserLoggedIn(true)
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const closeLogin = () => {
    setCloseLoginModal(true)
    setCloseSignupModal(false)
  }

  const closeSignup = () => {
    setCloseSignupModal(true)
    setCloseLoginModal(false)
  }

  const resetLogin = () => {
    setCloseLoginModal(false)
    setCloseSignupModal(false)
  }

  const resetSignUp = () => {
    setCloseSignupModal(false)
    setCloseLoginModal(false)
  }
  const handleLanguageChange= (language : any) => {
    setSelectedLanguage(language);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedContributorName = await getStorageData('contributorName');
        setContributorName(storedContributorName || '');
      } catch (error) {
        console.error('Error fetching contributor name:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
         {!!!props.contributorLogin && props.showHeardNav && <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={handleDrawerOpen}
          >
            <MenuIcon 
                className={classes.menuIcon}
            />
          </IconButton>}
          <div className={classes.displayFlex} onClick={props.goToLanding}  data-test-id="landingBTn">
            <img
                src={logo}
                
               
            />
             {(!!props.contributorLogin || !!props.showContributorLable || !!props.contributorContent || !!props.contributorPortfolio) && <span>
              <CustomTypography variant ="secondary" >Contributor</CustomTypography>
             </span>}
          </div>
         {!!!props.contributorLogin && props.showHeardNav &&
          <div
            className={classes.menuWrapperlg}
          >
            <div
                className={classes.menuListWrap}
            >
                <Box
                    ml={2}
                >
                    <CustomLink
                        variant={'primary'}
                        url={'#'}
                        component={'body12'}
                    >
                        BLOG
                    </CustomLink>
                </Box>
                <Box
                    ml={2}
                >
                    <CustomLink
                        variant={'primary'}
                        url={'#'}
                        component={'body12'}
                    >
                        SUPPORT CENTRE
                    </CustomLink>
                </Box>
            </div>
            <div
                className={classes.menuListWrap}
            >
                <Box
                    mr={2}
                >
                    <CustomLink
                        variant={'primary'}
                        url={'#'}
                        component={'body11'}
                        textTransform={'uppercase'}
                    >
                        by stock photos & videos
                    </CustomLink>
                </Box>
                <Box
                    mr={2}
                    className={classes.btn}
                >
                    <CustomModal
                        btnLabel='LOG IN'
                        closeBtn={true}
                        openProp={closeModalProp}
                        backRes={resetLogin}
                        variant={'secondary'}
                        size={'LargeJoinBTn'}
                    >
                        {!closeLoginModal ?
                          <EmailAccountLoginBlock
                            navigation={props.navigation}
                            id={'signin'}
                            callBack={changeModalProp}
                            closeLogin={closeLogin}
                            roleType={1}
                            forgetPasswordRoute='/Contributor/forgot-password'
                          />
                        :
                          <EmailAccountRegistartion
                            navigation={props.navigation}
                            id={'signup'}
                            callBack={changeModalProp}
                            roleType={1}
                            closeSignup={closeSignup}
                          />
                        }
                    </CustomModal>
                </Box>
                <Box
                    className={classes.btn}
                >
                    <CustomModal
                        btnLabel='Sign up'
                        closeBtn={true}
                        openProp={closeModalProp}
                        variant={'primary'}
                        size={'LargeJoinBTn'}
                        backRes={resetSignUp}
                    >
                        {closeSignupModal ?
                          <EmailAccountLoginBlock
                            navigation={props.navigation}
                            id={'signin'}
                            callBack={changeModalProp}
                            closeLogin={closeLogin}
                            roleType={1}
                            forgetPasswordRoute='/Contributor/forgot-password'
                          />
                        :
                          <EmailAccountRegistartion
                            navigation={props.navigation}
                            id={'signup'}
                            callBack={changeModalProp}
                            roleType={1}
                            closeSignup={closeSignup}
                          />
                        }
                    </CustomModal>
                </Box>
            </div>
          </div>
         }

         {!!props.languageOptions && <div className={classes.menuDropDown}>
          <div>
            <CustomDropDown startAdornment={<LanguageIcon />} options={props.languageOptions} selectedItem={props.selectedLanguage} selectNewItem={props.handleChangeLanguage}/>
          </div>
         </div> }

         {!!props.contributorLogin && <div className={classes.rightContainer}>
          <IconButton style={{backgroundColor:"#E9EAEA",color:""}}>
            <NotificationsNoneOutlinedIcon/>
          </IconButton>
          <Box className={classes.btnContainer}>
            <CustomButton   startIcon={<AddCircleOutlineIcon/>}  variant="primary" size="large" fullWidth="fullWidth"> Add</CustomButton>
          </Box>
          <ProfileButton aria-controls="simple-menu" aria-haspopup="true" >
              <img src={profile} />
            </ProfileButton>
         </div>}
         {!!props.contributorContent && <div className={classes.rightContainer}>
         <LanguageSelector aria-controls="simple-menu" aria-haspopup="true">
              <img src={language} />
              <SelectInput value={selectedLanguage} disableUnderline style={{color:"#73767A"}}onChange={(e) => handleLanguageChange(e.target.value)} IconComponent={ExpandMoreIcon}>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ar">Arabic</MenuItem>
      <MenuItem value="pr">Persian</MenuItem>
      <MenuItem value="hb">Hebrew</MenuItem>
      <MenuItem value="tr">Turkish</MenuItem>
    </SelectInput>
            </LanguageSelector>
          <ProfileButton aria-controls="simple-menu" aria-haspopup="true" >
              <img src={profile} />
              <CustomTypography variant="selectMultitext">{contributorName}</CustomTypography>
              <SelectInput value={selectedProfile} disableUnderline style={{color:"#73767A"}} IconComponent={ExpandMoreIcon}>
      <MenuItem value="as">Account Settings</MenuItem>
      <MenuItem value="mp">My Portfolio</MenuItem>
      <MenuItem value="sc">Support Center</MenuItem>
      <MenuItem value="cu">Contact Us</MenuItem>
      <MenuItem value="lg">Log Out</MenuItem>
    </SelectInput>
            </ProfileButton>
         </div>}
         {!!props.contributorPortfolio && 
         <div style={{display:"flex", justifyContent:"space-between", width:"100%"}} >
      <Tabs className={classes.leftContainer}>
        <Tab label="Earnings" />
        <Tab label="Portfolio" className={classes.portfolioTab}/>
        <Tab label="Insights" />
        <Tab label="request" />
      </Tabs>
      <div className={classes.rightContainer} >
      <CustomButton variant="primary" size="large" fullWidth="fullWidth" onClick={props.goToUpload}> upload</CustomButton>
         <LanguageSelector aria-controls="simple-menu" aria-haspopup="true">
              <img src={language} />
              <SelectInput value={selectedLanguage} disableUnderline style={{color:"#73767A"}}onChange={(e) => handleLanguageChange(e.target.value)} IconComponent={ExpandMoreIcon}>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ar">Arabic</MenuItem>
      <MenuItem value="pr">Persian</MenuItem>
      <MenuItem value="hb">Hebrew</MenuItem>
      <MenuItem value="tr">Turkish</MenuItem>
    </SelectInput>
            </LanguageSelector>
          <ProfileButton aria-controls="simple-menu" aria-haspopup="true" >
              <img src={profile} />
            </ProfileButton>
            </div>
         </div>}
         
        </Toolbar>
      </AppBar>
     {!!!props.contributorLogin && <Drawer
        className={classes.drawer}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
            <ListItem button onClick={handleDrawerClose}>
                <img src={logo}/>
            </ListItem>
            <ListItem button onClick={handleDrawerClose}>
                <CustomLink
                    variant={'primary'}
                    url={'#'}
                    component={'body12'}
                    textTransform={'uppercase'}
                >
                    BLOG
                </CustomLink>
            </ListItem>
            <ListItem button onClick={handleDrawerClose}>
                <CustomLink
                    variant={'primary'}
                    url={'#'}
                    component={'body12'}
                    textTransform={'uppercase'}
                >
                    Support Centre
                </CustomLink>
            </ListItem>
            <ListItem button onClick={handleDrawerClose}>
                <CustomLink
                    variant={'primary'}
                    url={'#'}
                    component={'body11'}
                    textTransform={'uppercase'}
                >
                    by stock photos & videos
                </CustomLink>
            </ListItem>
            <ListItem button onClick={handleDrawerClose}>
                <div
                    className={classes.btn}
                >
                    <CustomModal
                        btnLabel='LOGIN'
                        closeBtn={true}
                        openProp={closeModalProp}
                        variant={'secondary'}
                        size={'medium'}
                        backRes={resetLogin}
                    >
                        {!closeLoginModal ?
                          <EmailAccountLoginBlock
                            navigation={props.navigation}
                            id={'signin'}
                            callBack={changeModalProp}
                            closeLogin={closeLogin}
                            roleType={1}
                            forgetPasswordRoute='"/Contributor/forgot-password'
                          />
                        :
                          <EmailAccountRegistartion
                            navigation={props.navigation}
                            id={'signup'}
                            callBack={changeModalProp}
                            roleType={1}
                            closeSignup={closeSignup}
                          />
                        }
                    </CustomModal>
                </div>
            </ListItem>
            <ListItem button onClick={handleDrawerClose}>
                <div
                    className={classes.btn}
                >
                    <CustomModal
                        btnLabel='Sign up'
                        closeBtn={true}
                        openProp={closeModalProp}
                        variant={'primary'}
                        size={'medium'}
                        backRes={resetSignUp}
                    >
                        {closeSignupModal ?
                          <EmailAccountLoginBlock
                            navigation={props.navigation}
                            id={'signin'}
                            callBack={changeModalProp}
                            closeLogin={closeLogin}
                            roleType={1}
                            forgetPasswordRoute='/Contributor/forgot-password'
                          />
                        :
                          <EmailAccountRegistartion
                            navigation={props.navigation}
                            id={'signup'}
                            callBack={changeModalProp}
                            roleType={1}
                            closeSignup={closeSignup}
                          />
                        }
                    </CustomModal>
                </div>
            </ListItem>
        </List>
      </Drawer>}
    </div>
  );
};

export default Header;