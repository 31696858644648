import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
export interface Bookmark {
  id: string;
  type: string;
  attributes: {
    id: string;
    account_id: number;
    catalogue_id: number;
    created_at: string;
    updated_at: string;
    images: {
      url: string;
      status: string;
      type: string;
    };
    account_full_name: string;
    account_profile_image: string;
    collection_present: boolean;
  };
}
interface Collection {
  id: string;
  type: string;
  attributes: {
      id: number;
      collection_name: string | null;
      collection_type: string;
      collection_subtype: string;
      account_id: number;
      download: number;
      created_at: string;
      updated_at: string;
      images: {
          url: string;
          type: string;
      };
  };
  relationships: {
      galleries: {
          data: unknown[];
      };
  };
}
export interface TabType {
  id: number;
  label: string;
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedId: number
  selectedLabel: string;
  tabsMenu: TabType[];
  bookmarkData: Bookmark[];
  collectionData:Collection[];
  isLoading:boolean;
  totalFollowCount:any;
  followingData:any;
  searchValue: any;
  noFollowMsg:string;
  showErrorMsg:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class UserActivityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookmarksApiItemCallId: string = '';
  getCollectionApiCallId: string = '';
  createCollectionApiCallId:string = '';
  getBase64ApiCallId: string = '';
  getFollowingDataCallId:string="";
  getUnfollowDataCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedId: 1,
      selectedLabel: "Followings",
      tabsMenu : [{ id:1, label: `Followings (0)` },
      { id:2, label: "Purchase History" },
      { id:3, label: "Comments" },
      { id:4, label: "Favourites" },
      { id:5, label: "My Collections" },
      { id:6, label: "Downloads" },
      { id:7, label: "Hires" }],
      bookmarkData: [],
      collectionData: [],
      isLoading:true,
      totalFollowCount: 0,
      followingData:[],
      searchValue:"",
      noFollowMsg:"",
      showErrorMsg:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
   
      if (responseJson) {
        switch (apiRequestCallId) {
          case this.getBookmarksApiItemCallId:
            this.setState({bookmarkData: responseJson?.data})
            break;
          case this.getCollectionApiCallId:
            this.setState({collectionData: responseJson.message ? "" : responseJson?.data})
            this.setState({isLoading:false})
            break;
          case this.createCollectionApiCallId:
            this.getCollectionsData();
            this.getBookmarksData();
            break;
          case this.getBase64ApiCallId:
              this.downloadMedia(responseJson.data)
              break;
              case this.getFollowingDataCallId:
          this.setFollowingData(responseJson);
          break;
          case this.getUnfollowDataCallId:
          this.handleFollowingData();
          break;
          default:
            break;
        }
      } else {
        this.parseApiErrorResponse(errorReponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getBookmarksData();
    this.getCollectionsData();
    this.handleFollowingData()
   const selectedTab= await getStorageData("selectedIds")
   if(typeof selectedTab === "string"){
    this.setState({selectedId : Number(selectedTab)})
    removeStorageData("selectedIds")
   }

  }
  goToProfileTab = () => {
    const goToProfileTab: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToProfileTab.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileDetails");
    goToProfileTab.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToProfileTab);
  }
  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };
  downloadMedia = (base64 : any) =>{
    // Logic to download the image
    const imageUrl = `data:image/png;base64,${base64}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'product.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getBookmarksData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeAppliactionJson,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBookmarksApiItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBookmarksAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleChange = (event:React.ChangeEvent<{}>,value:number) => {
    const selectedTab = this.state.tabsMenu.find((tab: { id: number; }) => tab.id === value);
    if(selectedTab){
    const labelWithoutCount = selectedTab.label.replace(/\(\d+\)/, '');
    this.setState({ selectedId: value, selectedLabel: labelWithoutCount});
    }
  };
  getCollectionsData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeAppliactionJson,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCollectionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCollectionsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCollection = async(id:number) => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeAppliactionJson,
      "token": token
    };
    const matchingBookmark = this.state.bookmarkData.find((bookmark:Bookmark) => bookmark.attributes.catalogue_id == id);
    let type;
    if (matchingBookmark?.attributes.collection_present) {
        type = "remove";
    } else {
        type = "add";
    }
    const httpBody = {
      "collection":
      {
          "catalogue_id": id, 
          "type": type,
          "collection_subtype": "private"
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCollectionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCollectionsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDownloadBtn = async (id:any) => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBase64ApiCallId=requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBase64APiEndPoint+`?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFollowingData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeAppliactionJson,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFollowingDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowingAPiEndPoint(this.state.searchValue)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSearch= (event:any) => {
    this.setState({ searchValue: event.value }, () => {
      this.handleFollowingData();
      if (this.state.followingData == undefined) {
        this.setState({showErrorMsg:true})
      }
      if(this.state.searchValue !== ""){
        this.setState({showErrorMsg:true})
      }
    });
  }
  setFollowingData = async (responseJson:any) => {
    if(responseJson.message){
      this.setState({followingData: "", noFollowMsg:responseJson.message, isLoading: false})
    }
    else{
      this.setState({followingData: responseJson.data,noFollowMsg:"",isLoading:false, totalFollowCount: responseJson.meta.total}, () => this.setState({tabsMenu :[{ id:1, label: `Followings (${this.state.totalFollowCount})` },
      { id:2, label: "Purchase History" },
      { id:3, label: "Comments" },
      { id:4, label: "Favourites" },
      { id:5, label: "My Collections" },
      { id:6, label: "Downloads" },
      { id:7, label: "Hires" }]}))
    }
  }
  handleUnfollowBtn = async (contributorId: number)=> {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeAppliactionJson,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUnfollowDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUnfollowAPiEndPoint(contributorId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
