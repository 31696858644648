// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  TableContainer,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore, SaveAlt, Search } from "@material-ui/icons";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  sideBarContributoBottomNav,
  sideBarContributorListItem,
} from "../../../blocks/navigationmenu/src/NavigationMenuController";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import CustomDropDown from "../../../components/src/DesignSystem/CustomDropDown/CustomDropDown.web";
import CustomTable from "../../../components/src/DesignSystem/CustomTable/CustomTable.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import Input from "../../../components/src/DesignSystem/Input/Input.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import PaymentHistoryContributorController from "./PaymentHistoryContributorController.web";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";

export const configJSON = require("./config");

// Customizable Area End
export class PaymentHistoryContributor extends PaymentHistoryContributorController {
  render() {
    // Customizable Area Start

    const { classes } = this.props;

    return (
      <>
        <NavigationMenu
          navigation={this.props.navigation}
          id={""}
          contributorLogin={true}
          data-test-id="showCollection"
        />

        <SideBar
          id=""
          navigation={this.props.navigation}
          sideBarBottomNav={sideBarContributoBottomNav}
          sideBarListItem={sideBarContributorListItem}
        >
          <Box className={classes.paymentHistoryContributorTop}>
            <CustomTypography component="h7">PAYMENT HISTORY</CustomTypography>

            <Grid
              className={classes.paymentHistoryContributorControl}
              container
              spacing={3}
            >
              <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
                <Box className={classes.paymentHistoryContributorSelect}>
                  <CustomDropDown
                    data-test-id="status-dropdown"
                    variant="dropdownContent"
                    endAdornment={<ExpandMore />}
                    options={this.statusOptions}
                    selectedItem={this.state.status}
                    selectNewItem={this.changeStatus}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={9} md={5} lg={6} xl={9}>
                <Box className={classes.paymentHistoryContributorSearch}>
                  <Input
                    startAdornment={<Search />}
                    type="text"
                    placeholder="Search the transactions"
                    value={this.state.search}
                    data-test-id="search-input"
                    onChange={this.changeSearch}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                <Box className={classes.paymentHistoryContributorDownload}>
                  <CustomButton
                    data-test-id="download-payment-button"
                    variant="secondary"
                    fullWidth="fullWidth"
                    size="large"
                    type="button"
                    endIcon={<SaveAlt />}
                  >
                    DOWNLOAD
                  </CustomButton>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
                <Box className={classes.paymentHistoryContributorSelect}>
                  <CustomDropDown
                    data-test-id="sort-by-dropdown"
                    variant="dropdownContent"
                    endAdornment={<ExpandMore />}
                    options={this.sortOptions}
                    selectedItem={this.state.sortBy}
                    selectNewItem={this.changeSortBy}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.paymentHistoryContributorData}>
            <TableContainer
              className={classes.paymentHistoryContributorTableContainer}
            >
              <CustomTable
                hideCheckbox
                headers={this.headers}
                data={this.state.history}
              />
            </TableContainer>
          </Box>

          <Footer userType={"contributor"} navigation={""} />
        </SideBar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  paymentHistoryContributorTop: {
    padding: "24px 16px",
    backgroundColor: `${customTheme.palette.disabled.main}`,
  },

  paymentHistoryContributorControl: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    marginLeft: -2,
  },

  paymentHistoryContributorSearch: {
    "& fieldset": {
      borderColor: `${customTheme.palette.primary.main}`,
    },
  },

  paymentHistoryContributorSelect: {
    "& > div": {
      border: `1px solid ${customTheme.palette.primary.main}`,
      backgroundColor: customTheme.palette.white.main,
      padding: "11px 0px",

      "& .label": {
        fontSize: "14px !important",
      },
    },
  },

  paymentHistoryContributorData: {
    padding: "16px 28px 72px",
  },

  paymentHistoryContributorTableContainer: {
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "14px 0",

    "& thead": {
      "& tr": {
        backgroundColor: "initial !important",

        "& th": {
          padding: "16px !important",
        },
      },
    },

    "& tbody": {
      "& tr": {
        cursor: "pointer",

        "& td": {
          border: "none",
          padding: "16px !important",
        },

        "&:hover": {
          backgroundColor: customTheme.palette.white.light,
        },
      },
    },
  },

  paymentHistoryContributorDownload: {
    "& button": {
      fontWeight: "400 !important",
      fontFamily: "Outfit !important",
    },
  },
});
// Customizable Area End

export default withStyles(styles)(PaymentHistoryContributor);
