import React from "react";
import customTheme from "../../../src/DesignSystem/Theme/Theme.web";


const CustomTypography = (props: any) => {

    const typoStyles: any = {
        ...styles[props.variant],
        ...styles[props.component ? props.component : 'body3'],
        // ...styles[props.weight ? props.weight : 'light'],
        ...styles[`text_${props.textTransform}`]
    }

    return(
        <div>
            <span
                style={typoStyles}
                className="label"
            >
                {props.children}
            </span>
        </div>
    )
}

const styles: any = {
    primary: {
        color: customTheme.palette.primary.main
    },
    secondary: {
        color: customTheme.palette.secondary.main
    },
    white: {
        color: customTheme.palette.white.main
    },
    red:{
        color:customTheme.palette.red.main
    },
    redLight: {
        color:customTheme.palette.red.light
    },
    blue:{
        color:customTheme.palette.blue.main
    },
    success:{
        color:customTheme.palette.success.main
    },
    secondaryLight:{
        color:customTheme.palette.secondary.light
    },
    greyLight:{
        color:customTheme.palette.grey.light
    },
    primaryDark:{
        color: customTheme.palette.primary.dark
    },
    h1: {
        fontSize: '48px',
        lineHeight: '56px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    h2: {
        fontSize: '36px',
        lineHeight: '44px',
        fontWeight: '700',
        fontFamily: 'LemonMilk',
        letterSpacing:"2.54px"
    },
    h3: {
        fontSize: '30px',
        lineHeight: '40px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    h4: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '700',
        fontFamily: 'LemonMilk',
        letterSpacing:"1.5px"
    },
    h5: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    h6: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    h7:{
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: '500',
        fontFamily: 'LemonMilk',
        letterSpacing:"1px"
    },
    semiLight: {
        fontWeight: '500'
    },
    light: {
        fontWeight: '400'
    },
    title: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    title2: {
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: '500',
        fontFamily: 'LemonMilk',
        letterSpacing:"1px"
    },
    subTitle: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    subTitle2: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: '700',
        fontFamily: 'LemonMilk'
    },
    para: {
        fontSize: '12px',
        lineHeight: '15px',
        fontFamily: 'LemonMilk'
    },
    label12: {
        fontSize: '12px',
        lineHeight: '15px',
        fontWeight: '400',
        fontFamily: 'LemonMilk',
    },
    body: {
        fontSize: '10px',
        fontWeight: '500',
        fontFamily: 'LemonMilk'
    },
    bodyTest: {
        fontSize: '10px',
        fontWeight: '500',
        fontFamily: 'LemonMilk',
        lineHeight:"18px",
        letterSpacing:"0.5px"
    },
    body1: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '26px',
        fontFamily: 'LemonMilk',
        letterSpacing:"0.27px"
    },
    body112: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '26px',
        fontFamily: 'LemonMilk-Regular',
        letterSpacing:"0.27px"
    },
    body2: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        fontFamily: 'LemonMilk',
        letterSpacing:"0.24px"
    },
    body212: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        fontFamily: 'LemonMilk-Regular',
        letterSpacing:"0.24px"
    },
    body3: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '22px',
        fontFamily: 'LemonMilk',
        letterSpacing:"0.5px"
    },
    body4: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '20px',
        fontFamily: 'LemonMilk'
    },
    body5:{
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        fontFamily: 'LemonMilk'
    },
    body6: {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '20px',
        fontFamily: 'LemonMilk',
        letterSpacing: '0.18px'
    },
    body7: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        fontFamily: 'LemonMilk',
        letterSpacing:"0.5px"
    },
    body11:{
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"28px"
    }, 
    body13:{
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"140%"
    }, 
    body14:{
        fontSize: '10px',
        fontWeight: '400',
        fontFamily: 'LemonMilk',
        lineHeight: "13px",
        letterSpacing:"0.5px"
    }, 
    body15:{
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        color:customTheme.palette.secondary.main,
        lineHeight:2.5
    }, 
    body16:{
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"24px",
        color:customTheme.palette.grey.main,
    },
    body17:{
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"24px",
        color:customTheme.palette.secondary.main,
    }, 
    body18:{
        fontSize: '14px',
        fontWeight: '600',
        fontFamily: 'Outfit',
        lineHeight:"24px",
        color:customTheme.palette.primary.main,
    },
    body19:{
        fontSize: '18px',
        fontWeight: '500',
        fontFamily: 'lemonMilk',
        lineHeight:"24px",
        letterSpacing:"3px",
        color:customTheme.palette.primary.main,
    },
    body20: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        fontFamily: 'LemonMilk',
        wordWrap:"break-word"
    },
    body21: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        fontFamily: 'Outfit',
    },
    body12400:{
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"18px"
    },
    body11500:{
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Outfit',
        lineHeight:"18px"
    }, 
    body11bold:{
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Outfit',
        lineHeight:"24px"
    }, 
    body12:{
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"22px"
    },
    body22:{
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"22px",
    },
    body8: {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily:"Outfit",
        lineBreak: "anywhere",
    },
    body9: {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily:"lemonMilk",
        lineBreak: "anywhere",
    }, 
    body10: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '22px',
        fontFamily: 'Outfit',
    },
    outfit14px: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '15px',
        fontFamily: 'Outfit',
    },
    text_capitalize: { 
        textTransform: 'capitalize' 
    },
    text_lowercase: { 
        textTransform: 'lowercase' 
    },
    text_none: { 
        textTransform: 'none' 
    },
    text_uppercase: { 
        textTransform: 'uppercase' 
    },
    text_decoration:{
        textDecoration:"underline"
    },
    heading:{
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: 'normal',
        fontFamily: "Outfit",
    },
    error:{
        fontSize: '200px',
        fontWeight: 600,
        lineHeight: 'normal',
        fontFamily: "Outfit",
    },
    label:{
        fontSize: '10px',
        fontWeight: '300',
        lineHeight: 'normal',
        fontFamily: "LemonMilk"
    },
    labelWObold:{
        fontSize: '10px',
        fontWeight: '300',
        lineHeight: 'normal',
        fontFamily: "LemonMilk-Regular"
    },
    outlineBody2: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'LemonMilk'
    },
    header1:{
        fontSize: '20px',
        fontWeight: '500',
        fontFamily: 'LemonMilk',
        letterSpacing:"1px"
    },
    header2:{
        fontSize: '20px',
        fontWeight: '500',
        fontFamily: 'Outfit',
        lineHeight:"25px"
    },
    outfitBody2:{
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Outfit-Light'
    },
    outfitBody3:{
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Outfit-Light'
    },
    outfitLabelXs:{
        fontSize: '10px',
        fontWeight: '400',
        fontFamily: 'Outfit-Light'
    },
    outfitBody:{
        fontSize: '10px',
        fontWeight: '400',
        fontFamily: 'Outfit'
    },
    outfitBody4: {
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: '500',
        fontFamily: 'Outfit-Bold'
    },
    heading2:{
        fontSize: '2rem',
        fontWeight: '500',
        fontFamily: 'LemonMilk'
    },
    labelOutfit1:{
        fontSize: '20px',
        fontWeight: '400',
        fontFamily: 'Outfit-Bold',
    },
    outfitHeading1:{
        fontSize: '32px',
        fontWeight: '400',
        fontFamily: 'Outfit'
    },
    outfitHeading2:{
        fontSize: '32px',
        fontWeight: '500',
        fontFamily: 'Outfit'
    },
    outfitBody7:{
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'lemonMilk',
        fontStyle:"normal",
        lineHeight:"normal",
        color:customTheme.palette.secondary.main
    },
    outfitBody6:{
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        fontStyle:"normal",
        lineHeight:"normal",
    },
    outfitBody8:{
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        fontStyle:"normal",
        lineHeight:"normal",
    },
    outfitBody1:{
       fontSize: '16px',
       fontFamily:"Outfit-Bold",
       fontStyle:"normal",
       fontWeight:400,
       lineHeight:"normal"
    },
    lemonHeading3:{
        fontSize: '32px',
        fontWeight: '500',
        fontFamily: 'LemonMilk',
        letterSpacing:"1.5px"
    },
    outfitHeading5:{
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: "Outfit-Light",
            lineHeight:"30px",
            wordWrap:"anywhere"
        },
    outfitHeading6:{
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: "Outfit-Light",
        lineHeight:"30px",
    },
    outfitHeading6H20:{
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: "Outfit",
        lineHeight:"20px",
    },
    outfitHeading7:{
        fontSize: '24px',
        fontWeight: 'bold',
        fontFamily: "Outfit-Light",
        lineHeight:"30px",
    },
    needHelp:{
        color:customTheme.palette.secondary.main,
        fontSize:"16px",
        textDecoration:"underline",
        display:"flex",
        gap:"20px",
        justifyContent:"flex-end",
    },
    selectMultitext:{
        color:customTheme.palette.secondary.main,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        fontFamily: "Outfit-Light",
    },
    contentfEature:{
        color:customTheme.palette.secondary.main,
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: "Outfit-Light",
    },
    bannerText:{
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: "Outfit",
    },
    font_color:{
        color:customTheme.palette.secondary.main
    },
    font_family:{
        fontFamily: "Outfit",
    },
    FaqHead:{
        color:"#FFFFFF",
        fontSize: '44px',
        fontWeight: '700',
        fontFamily: "LemonMilk",
        letterSpacing:"1.5",
        lineHeight:"60px",
    },
    linkText:{
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '28px',
        fontFamily: "Outfit",
        textDecoration:"underline",
        
    },
    emailText:{
        fontSize: '12px',
        fontWeight: '500',
        fontFamily: "Outfit",
        color:"#73767A"
    },
   modalFont:{
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: 'normal',
        fontFamily: 'Outfit-Bold'
    },
    FaqHeading: {
        fontSize: '64px',
        lineHeight: '98px',
        fontWeight: '400',
        fontFamily: 'Outfit'
    },
    FaqQuestionHeading: {
        fontSize: '40px',
        lineHeight: '60px',
        fontWeight: '400',
        fontFamily: 'Outfit'
    },
    body115:{
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Outfit',
        lineHeight:"24px"
    }, 
    body116:{
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'Outfit',
        lineHeight:"24px"
    }, 
    body1111:{
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Outfit',
        lineHeight:"22px"
    }, 
    title111:{
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: '500',
        fontFamily: 'LemonMilk',
        letterSpacing:'1.5px'
    },
    accountText:{
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Outfit',
        lineHeight:"20px",
        letterSpacing:"0.24px",
    },
    footerhead:{
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'LemonMilk',
            lineHeight:"18px",
            letterSpacing:"1.5px"
    },
    cardHead:{
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '30px',
        fontFamily: "Outfit", 
        letterSpacing:"0.27px"
    },
    body1100:{
        fontSize: '10px',
        fontWeight: '300',
        fontFamily: 'LemonMilk',
        lineHeight:"13px",
        letterSpacing:"0.5px",

    },
    body111:{
        fontSize: '14px',
        fontFamily: "Outfit",
        lineHeight:"20px",
        fontWeight:400,
        letterSpacing:"1.5px"
       },
}

export default CustomTypography