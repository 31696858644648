import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";

// Customizable Area Start
export const configJSON = require("./config");

export interface PaymentHistoryOption {
  label: string;
  value: any;
  checked?: boolean;
}

export interface PaymentHistory {
  id: string;
  attributes: {
    name: string;
    status: string;
    type: string;
    fee: number;
    netTotal: number;
    createdAt: Date | string;
  };
}
// Customizable Area End

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  search: string;
  status: PaymentHistoryOption;
  sortBy: PaymentHistoryOption;
  history: PaymentHistory[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentHistoryContributorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  headers = [
    {
      id: "createdAt",
      label: "Date",
      type: "date",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      id: "name",
      label: "Name",
      type: "highlight",
    },
    {
      id: "status",
      label: "Status",
      type: "payment-status",
    },
    {
      id: "fee",
      label: "Fee",
      type: "money",
    },
    {
      id: "netTotal",
      label: "Net total",
      type: "money",
    },
    {
      id: "actions",
      label: "Actions",
      type: "menu",
    },
  ];

  sortOptions = [
    { label: "Sort By", value: "Sort By" },
    { label: "Last Updated", value: "last_updated" },
    { label: "Newest First", value: "newest_first" },
    { label: "Oldest First", value: "oldest_first" },
  ];

  statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      search: "",
      status: configJSON.activity[0],
      sortBy: configJSON.sorting[0],
      history: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const history = Array.from({ length: 10 }).map(() => {
      return {
        id: uuidv4(),
        attributes: {
          name: "Lana rodes",
          status: "Completed",
          type: "Payment from",
          fee: 1.99,
          netTotal: 14.99,
          createdAt: "2024-02-22T02:58:42.986Z",
        },
      };
    });

    this.setState({
      history,
    });
  }

  // Customizable Area Start
  changeSearch = (target: { value: string }) => {
    this.setState({
      search: target.value,
    });
  };

  changeSortBy = (sortBy: PaymentHistoryOption) => {
    this.setState({
      sortBy,
    });
  };

  changeStatus = (status: PaymentHistoryOption) => {
    this.setState({
      status,
    });
  };
  // Customizable Area End
}
