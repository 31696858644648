import React, { CSSProperties } from "react";
// Customizable Area Start
import { Grid, styled, Box, CircularProgress } from "@material-ui/core";
const images = require("./assets");
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
import Input from "../../../components/src/DesignSystem/Input/Input.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import { configJSON } from "./UserProfileBasicController";
import UserActivityController, {Props} from "./UserActivityController";
// Customizable Area End

// Customizable Area Start
interface WebStyle {
    [key: string]: CSSProperties;
}
// Customizable Area End

export default class UserFollowing extends UserActivityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { followingData, searchValue, noFollowMsg, showErrorMsg, isLoading } = this.state;
        return (
            <>
                <Grid style={webStyle.showFollowingData}>
                    <Grid style={webStyle.showSearchBar}>
                        <StyledBox>
                            <Input
                                data-test-id="search_data"
                                placeholder="Search Contributors"
                                isSearch
                                type='text'
                                value={searchValue}
                                onChange={(event:any) => this.handleSearch(event)}
                                startAdornment={<img src={images.search} alt="Search" />}
                            />
                        </StyledBox>
                    </Grid>
                    <Grid style={webStyle.setAllData}>
             {isLoading && <CircularProgress size={20} color="inherit" style={webStyle.noFoundStyle}/>}
                        {!isLoading  && followingData.length>0 ? followingData?.map((data: any, index: number) => {
                            return (
                                <Grid style={webStyle.contributorProfileData}>
                                    <Grid style={webStyle.profileStyle}>
                                        <Box style={webStyle.showData}>
                                            <Box style={webStyle.contributorProfile}>
                                                <img src={images.profileIcons} alt="profile" style={webStyle.profileLogo} />
                                                <Box style={webStyle.details}>
                                                    <CustomTypography component="bodyTest">{data.attributes.user_detail.name}</CustomTypography>
                                                    <CustomTypography variant="secondaryLight" component="modalFont">{data.attributes.user_detail.followers_count} Followers</CustomTypography>
                                                </Box>
                                            </Box>

                                            <img src={images.more} alt="more" style={webStyle.moreOption} />
                                        </Box>
                                        <CustomButton startIcon={<img src={images.follow} />} variant={"downloadPreviewBtn"} data-test-id="handleUnfollowBtn" onClick={()=> this.handleUnfollowBtn(data.attributes.contributor_id)}
                                            size={"smallButton"}>FOLLOWING</CustomButton>
                                    </Grid>
                                    <Grid style={webStyle.showAlImages}>
                                        {data.attributes.catalogues.map((images: any) => {
                                            const contentUrl = images.attributes.images.url
                                            return (
                                                images.attributes.images.type.startsWith("image") ?
                                                    <img src={contentUrl} style={webStyle.imageSize} /> :
                                                    <video style={webStyle.imageSize} controls>
                                                        <source src={contentUrl} />
                                                    </video>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            )
                           
                        }) : 
                        <Box style={webStyle.noFoundStyle}>
                        <CustomTypography >{showErrorMsg ? configJSON.noFound : noFollowMsg}</CustomTypography>
                        </Box> 
                        }
                    </Grid>
                </Grid>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle: WebStyle = {
    showFollowingData: {
        width: "95%",
    },
    showSearchBar: {
        justifyContent: "flex-end",
        display: "flex",
        paddingBottom: "20px"
    },
    contributorProfile: {
        display: "flex",
        gap: "10px"
    },
    profileStyle: {
        width: "20%",
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    showData: {
        display: "flex",
        justifyContent: "space-between"
    },
    contributorProfileData: {
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        padding: "20px",
        display: 'flex',
        justifyContent: "space-evenly"
    },
    showAlImages: {
        gap: "30px",
        display: 'flex',
        width:"80%",
        maxWidth:"896px",
        overflowX:"auto"
    },
    profileLogo: {
        width: "34px",
        height: "34px"
    },
    moreOption: {
        width: "16px",
        height: "16px",
        paddingTop: "10px"
    },
    details: {
        display: "flex",
        flexDirection: "column",
        gap: '5px'
    },
    setAllData: {
        display: "flex",
        gap: "30px",
        flexDirection: "column"
    },
    imageSize: {
        width: "160px",
        height: "125px"
    },
    noFoundStyle:{
        width:"100%",
        display:"flex",
        justifyContent:"center"
    }
};
const StyledBox = styled(Box)({
    "& .MuiOutlinedInput-root": {
        fontFamily: "Outfit",
        fontSize: "14px !important",
        fontWeight: 500,
        color: `${customTheme.palette.secondary.main} !important`,
        padding: "14px 16px",
        height: "48px",
        width: "394px",
        backgroundColor: customTheme.palette.white.light,
        border: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "0px"
    }
});
// Customizable Area End
