import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from 'react';
export const images= require("./assets")
import { getStorageData, removeStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  selectedContent: string;
  selectedCategryContent:string;
  selectedReviewContent:string;
  submitModal: boolean;
  showUploadedData:any;
  selectedSingleContent:any;
  goToPortfolioPage:boolean;
  title: any;
  value:number;
  inputValue: string;
  keywords: string[];
  suggestions: string[];
  selectedLabel:any;
  multiSelectActive: boolean,
  showPendingData: any,
  showReviewedData:any,
  description: any,
  isChecked:any,
  location:any,
  showCategoryData: any,
  selectedCategoryIndex: any,
  totalSubmit: any,
  totalPending:any,
  totoalReviewed: any,
  openlist: any,
  isPreviewOpen:boolean,
  fullConetnt: any,
  deleteModal:boolean,
  deleteAllModal:boolean,
  submittedDate: any,
  reviewDate: any,
  locationData: any,
  suggestionLocation: any,
  showAlert:boolean,
  alertType:any,
  userSuccessAlert:any,
  showSubmiMoreModal:boolean,
  isLoading:boolean,
  isSmallScreen:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CataloguePortfolioController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
      getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    // Customizable Area Start
      arrayHolder: [],
      token: "",
      selectedContent: 'images',
      selectedCategryContent:'newest',
      selectedReviewContent:'all',
      submitModal:false,
      showUploadedData:[],
        selectedSingleContent: [],
        goToPortfolioPage:false,
        title:"",
        submittedDate:"",
        reviewDate:"",
       value:0,
       inputValue: "",
       keywords: [],
       suggestions: [],
       selectedLabel:'images',
       multiSelectActive: false,
       showPendingData:[],
       showReviewedData:[],
        description: "",
        isChecked: false,
        location:"",
        showCategoryData:[],
        selectedCategoryIndex: null,
        totalSubmit: 0,
        totalPending:0,
        totoalReviewed:0,
        openlist:null,
        isPreviewOpen: false,
        fullConetnt: null,
        deleteModal:false,
        deleteAllModal: false,
        locationData:[],
        suggestionLocation:[],
        showAlert:false,
        alertType:'success',
        userSuccessAlert:"",
        showSubmiMoreModal:false,
        isLoading:false,
        isSmallScreen: window.innerWidth <= 768,
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
  // Customizable Area Start
    super.componentDidMount();
    this.getToken();
    this.showUploadedContent();
    this.showAllCategoryData();
    this.showAllLocation()
    this.redirectInvalidAccount()
  window.addEventListener('resize', this.handleResize);
  // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.showContentCallId:
          this.setshowUploadedContent(responseJson);
          break;
        case this.showPendingCallId:
          this.setShowPendingApi(responseJson);
          break;
        case this.deleteAllContentCallId:
          this.setState({deleteAllModal: false, value:0, showAlert:true, 
            userSuccessAlert: "All Catalogue Deleted Successfully", alertType:"delete"})
          this.showUploadedContent();
          this.showPendingData();
          this.showReviewedData()
          break;
        case this.showAllCategoryDataCallId:
          this.setState({ showCategoryData: responseJson.data });
          break;
        case this.showReviewedDataCallId:
          this.setState({
            showReviewedData: responseJson.data,
            totoalReviewed: responseJson.meta.total,
            isLoading:false
          });
          break;
        case this.deleteSingleContentCallId:
    this.setState({deleteModal: false, showAlert:true, 
      userSuccessAlert: "Catalogue Deleted Successfully", alertType:"delete"})
          if(this.state.value == 1){
            this.showPendingData()
          }
          else if(this.state.value == 2){
            this.showReviewedData()
          }
          else{
          this.showUploadedContent();
          }
          break;
        case this.saveDetailsCallId:
        case this.contentInfoCallId:
          this.setContentSubmitSave(responseJson)
          break;
          case this.showAllLOcationCallId:
            this.setState({locationData: responseJson})
            break;
        default:
      }
    // Customizable Area End
  }

  // Customizable Area Start
  handleContentChange= (event : any) => {
    const { value,label } = event.target;
    this.setState({ selectedContent: value as string, selectedLabel:label },
        ()=> this.showUploadedContent());
  }
  handleContentCategoryChange= (event : ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    this.setState({ selectedCategryContent: value as string }, () => this.showPendingData());
  }
  handleContentReviewChange= (event : ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    this.setState({ selectedReviewContent: value as string }, () => this.showReviewedData());
  }
  goToUpload =() =>{
    const goToPortfolioCatalogue: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToPortfolioCatalogue.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
    goToPortfolioCatalogue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToPortfolioCatalogue);
  }
  goToDashboard = () => {
    const goToPortfolioCatalogue: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToPortfolioCatalogue.addData(getName(MessageEnum.NavigationTargetMessage), "LadingPageAfterLoginContributor");
    goToPortfolioCatalogue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToPortfolioCatalogue);
  }
handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue,selectedSingleContent :[] })
    this.fetchData(newValue);
    
}
fetchData = (values:any) =>{
  switch(values){
    case 1:
      this.showPendingData();
      break;
      case 2:
        this.showReviewedData();
  }
}
  showContentCallId : string ="";
  showUploadedContent = async () =>{
    this.setState({ isLoading: true });
      const token = await getStorageData("authContributorToken");
      const header = {
        token: token,
      };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.showContentCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.showContentEndPoint(this.state.selectedContent)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return this.showContentCallId;
  }
  setshowUploadedContent = (responseJson:  any) =>{
    this.setState({showUploadedData : responseJson.data, totalSubmit: responseJson.meta.total, isLoading:false})
  }
  handleImageClick = (index: any, contentName: any, contentUrl: any) => {
    const { selectedSingleContent, multiSelectActive} = this.state;
  
    if (multiSelectActive) {
      const updatedSelectedContent = selectedSingleContent.includes(index)
        ? selectedSingleContent.filter((selectedIndex: any) => selectedIndex !== index)
        : [...selectedSingleContent, index];
  
      this.setState({ selectedSingleContent: updatedSelectedContent }, () => this.handleFillDetailsCall());

    } else {
      this.setState({
        selectedSingleContent: [index],
        fullConetnt: contentUrl
      }, () => this.handleFillDetailsCall());
    }
  };
  
  handleMultiSelect = () =>{
    this.setState({multiSelectActive : true})
  }
  goToSubmiTab =() =>{
    this.setState({value: 0})
  }
  handleFillDetailsCall = () => {
    const { selectedSingleContent, showPendingData, showReviewedData, value, showUploadedData } = this.state;
  
    const processItem = (item: any) => {
      const newTitle = item.attributes.title || '';
      const newDescription = item.attributes.description || '';
      const newCategory = item.attributes.category?.id || '';
      const newLocation = item.attributes.location || '';
      const newKeyword = item.attributes.keyword_array || '';
      const newNsfw = item.attributes.nsfw_content || false;
      const newSubmittedDate = item.attributes.created_at || '';
      const newReviewDate = item.attributes.updated_at;
      this.setState({
        description: newDescription,
        selectedCategoryIndex: newCategory,
        location: newLocation,
        keywords: newKeyword,
        isChecked: newNsfw,
        title: newTitle,
        submittedDate: newSubmittedDate,
        reviewDate: newReviewDate,
      });
    };
  
    switch (value) {
      case 1:
        const pendingItem = showPendingData.find((item: any) => selectedSingleContent.includes(item.id));
        if (pendingItem) {
          processItem(pendingItem);
        }
        break;
      case 2:
        const reviewedItem = showReviewedData.find((item:any) => selectedSingleContent.includes(item.id));
        if (reviewedItem) {
          processItem(reviewedItem);
        }
        break;
      default:
        const uploadedItem = showUploadedData.find((item:any) => selectedSingleContent.includes(item.id));
        if (uploadedItem) {
          processItem(uploadedItem);
        }
    }
  };
  

  deleteAllContentCallId : string = "";
  deleteAllContent = async() =>{
    const token = await getStorageData("authContributorToken");
    const header = {
      token: token,
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAllContentCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteAllContentEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return this.deleteAllContentCallId;
}

deleteSingleContentCallId : string = "";
deleteSingleContent = async() =>{
  const token = await getStorageData("authContributorToken");
  const header = {
    token: token,
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSingleContentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletedSingleEndPoint(this.state.selectedSingleContent)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.deleteSingleContentCallId;
}
contentInfoCallId : string = "";
handleContentInfo = async() =>{
  this.setState({showSubmiMoreModal: true})
    const {selectedSingleContent, isChecked,location, title, description, keywords, selectedCategoryIndex} = this.state
    const token = await getStorageData("authContributorToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const httpBody={
        "id":selectedSingleContent, 
        "title": title,
        "description": description,
        "nsfw_content": isChecked,
        "location": location,
        "keyword_array": keywords,
        "category_id": selectedCategoryIndex
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.contentInfoCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contentInfoEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return this.contentInfoCallId;
}
showPendingCallId : string ="";
  showPendingData = async () =>{
    this.setState({ isLoading: true });
      const token = await getStorageData("authContributorToken");
      const header = {
        token: token,
      };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.showPendingCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.categoryContentEndPoint(this.state.selectedCategryContent)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return this.showPendingCallId;
  }
  setShowPendingApi = (responJson : any) => {
   this.setState({showPendingData : responJson.data, totalPending : responJson.meta.total, isLoading: false})
  }

  handleInputKeywordChange = (event: any) => {
    const inputValue = event.value;
    this.setState({
      inputValue,
    });
  };

  handleAddKeyword = (keyword: string) => {
    if (!this.state.keywords.includes(keyword)) {
      this.setState((prevState) => ({
        inputValue: "",
        keywords: [...prevState.keywords, keyword],
      }));
    }
  };

  handleRemoveKeyword = (removedKeyword: string) => {
    this.setState((prevState) => ({
      keywords: prevState.keywords.filter(
        (keyword) => keyword !== removedKeyword
      ),
    }));
  };
  handleDeleteKeyword = (removedKeyword: string) => {
    this.setState((prevState) => ({
      suggestions: prevState.suggestions.filter(
        (keyword) => keyword !== removedKeyword
      ),
    }));
  }
  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const { inputValue } = this.state;
      if (inputValue.trim() !== "") {
        this.handleAddKeyword(inputValue.trim());
      }
    }
  };
  handleFormChange = (event : any) =>{
    const {value, name} = event
    
    if(name == 'title'){
    const truncatedValue = value.slice(0, 100);
      this.setState({title: truncatedValue})
    }
if (name == 'description'){
  const truncatedValue = value.slice(0, 500);
    this.setState({description : truncatedValue})
}
  }
  handleCheckBoxChange = () => {
    this.setState(
      (prevState) => ({ isChecked: !prevState.isChecked })
    );
  };

  showAllCategoryDataCallId : string ="";
  showAllCategoryData = async () =>{
      const token = await getStorageData("authContributorToken");
      const header = {
        token: token,
      };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.showAllCategoryDataCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.showCategoryEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return this.showAllCategoryData;
  }
  handleCategoryChange = (event: any) => {
    const selectedCategoryIndex = event.target.value;
    this.setState({ selectedCategoryIndex: selectedCategoryIndex}, () => 
    this.state.showCategoryData.forEach((category: any) => {
      if (category.id == selectedCategoryIndex) {
          this.setState({suggestions : category.attributes.keywords})
      }
  }));
  };
  closeModal = () =>{
    this.setState({submitModal : false})
  }
  handleSelect = async () => {
     await removeStorageData('authContributorToken');
     await removeStorageData('contributorName');
     const goToLandingPage: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToLandingPage.addData(getName(MessageEnum.NavigationTargetMessage), "Contributor");
    goToLandingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToLandingPage);
  };
  redirectInvalidAccount = async () => {
    const tokenExist:any = await getStorageData('authContributorToken');
    if (!tokenExist) {
      const goToLandingPage: Message = new Message(getName(MessageEnum.NavigationMessage));
      goToLandingPage.addData(getName(MessageEnum.NavigationTargetMessage), "Contributor");
      goToLandingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(goToLandingPage);
    }
};
  showReviewedDataCallId : string ="";
  showReviewedData = async () =>{
    this.setState({ isLoading: true });
      const token = await getStorageData("authContributorToken");
      const header = {
        token: token,
      };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.showReviewedDataCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.reviewContentEndPoint(this.state.selectedReviewContent)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return this.showReviewedDataCallId;
  }
  handleMoreList = (event: React.MouseEvent<HTMLButtonElement>, conetntUrl: any) =>{
    this.setState({openlist : event.currentTarget, fullConetnt: conetntUrl})
  }
  handlePreviewClose = () =>{
    this.setState({isPreviewOpen:false, openlist : false})
  }
  handleContentPreview = () => {
    this.setState({isPreviewOpen:true})
  }
  saveDetailsCallId : string ="";
  saveDetailsBtn = async() =>{
  this.setState({showSubmiMoreModal: false})

    const {selectedSingleContent, title,isChecked,location, description, keywords, selectedCategoryIndex} = this.state
    const token = await getStorageData("authContributorToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const httpBody={
        "id":selectedSingleContent, 
        "title": title,
        "description": description,
        "nsfw_content": isChecked,
        "location": location,
        "keyword_array": keywords,
        "category_id": selectedCategoryIndex
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.saveDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.saveDetailsEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return this.saveDetailsCallId;
  }
  closeList = () => {
      this.setState({openlist: false})
  }
  goToLanding = () => {
    const goToPortfolioCatalogue: Message = new Message(getName(MessageEnum.NavigationMessage));
    goToPortfolioCatalogue.addData(getName(MessageEnum.NavigationTargetMessage), "ContributorHome");
    goToPortfolioCatalogue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToPortfolioCatalogue);
  }
  deleteContentModal = () => {
    this.setState({deleteModal: true, deleteAllModal:false})
  }
  closeDeleteModal = () => {
    this.setState({deleteModal: false})
  }
  handleDeleteCall = () => {
    if(this.state.deleteAllModal === true){
      this.deleteAllContent()
    }
    else{
      this.deleteSingleContent()
    }
  }
  deleteAllModalOpen = () => {
    this.setState({deleteModal: true, deleteAllModal:true})
  }
  
  isContentVisible = (value: any) => {
    const {
      showUploadedData,
      showPendingData,
      showReviewedData,
      isSmallScreen,
      selectedSingleContent
    } = this.state;
  
    if (
      (showUploadedData.length === 0 && value === 0) ||
      (showPendingData.length === 0 && value === 1) ||
      (showReviewedData.length === 0 && value === 2)
    ) {
      return 'none';
    }

    return this.isContentVisibleBlock(value, isSmallScreen, selectedSingleContent);
};

isContentVisibleBlock = (value: any, isSmallScreen: boolean, selectedSingleContent: any[]) => {
    const {
      showUploadedData,
      showPendingData,
      showReviewedData,
    } = this.state;

    if (
      isSmallScreen &&
      selectedSingleContent.length > 0 &&
      ((showUploadedData.length > 0 && value === 0) ||
      (showPendingData.length > 0 && value === 1) ||
      (showReviewedData.length > 0 && value === 2))
    ) {
      return 'block';
    }

    if (isSmallScreen &&
      ((showUploadedData.length > 0 && value === 0) ||
      (showPendingData.length > 0 && value === 1) ||
      (showReviewedData.length > 0 && value === 2))
    ) {
      return 'none';
    }
    
    return 'block';
};

  
  isContentWidth = (value: any) => {
    const{showUploadedData, showPendingData, showReviewedData} = this.state
    if (
      (showUploadedData.length === 0 && value === 0) ||
      (showPendingData.length === 0 && value === 1) ||
      (showReviewedData.length === 0 && value === 2)
    ) {
      return '100%';
    } 
    else if((showUploadedData.length >0 && value === 0 && this.state.isSmallScreen) || (showPendingData.length > 0 && value === 1 && this.state.isSmallScreen) ||
    (showReviewedData.length > 0 && value === 2 && this.state.isSmallScreen)) {
      return '100%';
    }
    else {
      return '60%';
    }
  };
  showAllLOcationCallId : string = "";
  showAllLocation = async () =>{
    const token = await getStorageData("authContributorToken");
    const header = {
      token: token,
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.showAllLOcationCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allCountryEndPoints
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.getMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return this.showAllLOcationCallId;
}

handleInputLocationChange = (event: any) => {
  const location = event.value;
  this.setState({ location }, () => {
    this.filterSuggestions();
  });
};

filterSuggestions = () => {
  const { location, locationData } = this.state;
  const filteredSuggestions = locationData.filter((suggestion: any) =>
    suggestion.toLowerCase().includes(location.toLowerCase()),
  );
  this.setState({ suggestionLocation: filteredSuggestions });
};

handleSuggestionClick = (suggestion: any) => {
  this.setState({ location: suggestion, suggestionLocation: [] });
};
setContentSubmitSave =(response: any) => {
if(response.message){
  this.setState({showAlert : true,
    alertType:"error",
    userSuccessAlert:response.message
  })
}
else{
   if(this.state.showSubmiMoreModal=== true){
    this.setState({submitModal : true})
    this.showUploadedContent();
  this.setState({selectedCategoryIndex: null, suggestions:[]})
  }
  else{
    this.setState({showAlert : true,
      alertType:"success",
      userSuccessAlert:"Successfully Saved"
    })
    this.showUploadedContent();
  }
}
}
handleAlertClose = () => {
  this.setState({ showAlert: false });
};
showAlertType = () => {
  const {alertType} = this.state;
  if (alertType ===  "success"){
    return "success"
  }
  else if(alertType ===  "delete"){
    return 'delete'
  }
  else{
    return 'error'
  }
}
handleResize = () => {
  this.setState({ isSmallScreen: window.innerWidth <= 768 });
};
handleShowContentScreen= () => {
  this.setState({selectedSingleContent: []})
}
showTabBtn = () => {
  if(this.state.value === 0){
    return "Submit"
  }
  if(this.state.value === 1){
    return "Pending"
  }
  else{
    return "Reviewed"
  }
}
async componentWillUnmount() {
  window.removeEventListener('resize', this.handleResize);
}
  // Customizable Area End
}
