Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.reviewApprovalAPiEndPoint = "review_and_approvals/review_and_approvals/";
exports.reviewApprovalApiContentType = "application/json";
exports.reviewApprovalShowApiMethodType = "GET";
exports.reviewApprovalCreateApiMethodType = "POST";
exports.reviewApprovalUpdateApiMethodType = "PUT";
exports.reviewApprovalDeleteApiMethodType = "DELETE";

// Customizable Area Start
exports.errorSearchInputNotEmpty = "Please enter search text";
exports.placeHolderSearchTxt = "Search by review id";
exports.placeHolderReviewableIdInputTxt = "Reviewable id";
exports.placeHolderCommentableTypesTxt = "Commentable Types";
exports.searchBtnTxt = "Search";
exports.approveBtnTxt = "Approve";
exports.rejectBtnTxt = "Reject";
exports.waiting = "waiting";
exports.approved = "approved";
exports.rejected = "rejected";
exports.noReviewsFound = "No reviews found";
exports.submitBtnTxt = "Submit";
exports.commentableTypeLabel = "Select Reviewable Type";
exports.commentableTypeComment = "BxBlockComments::Comment";
exports.commentableTypePost = "BxBlockComments::Post";
exports.comment = "Comment";
exports.post = "Post";
exports.message = "Message";
exports.content = "Content";
exports.id = "Id";
exports.reviewable = "Reviewable";
exports.reviewableId = "Reviewable Id";
exports.reviewableType = "Reviewable Type";

exports.btnColor = "#6200EE";
exports.sortBy = [
  {label:"Sort By",value:"sortBy"},
  {label:"Newest First",value:"newest"},
  {label:"Oldest First",value:"oldest"},
]
exports.contentLabelText = "Content details"
exports.typeLabelText = "Type"
exports.dateLabelText = "Date"
exports.userLabelText = "User details"
exports.priceLabelText = "Price"
exports.statusLabelText = "Status"
exports.actionsLabelText = "Actions"
exports.acceptBtnLabel='Accept'
exports.actionLabelText = "Actions"
exports.rejectBtnLabel='Reject'
exports.contentTitle = 'Title'
exports.titlePlaceholder = 'Enter Title'
exports.contentDescription = 'Description'
exports.descriptionPlaceholder = 'Enter Mountains, Landscape beautiful nature image. The perfect mountain aerial shot.'
exports.contentSpecification ='Technical Specification'
exports.specificationPlaceholder = 'Enter the specification'
exports.contentCategory ='Category'
exports.categoryPlaceholder ='Choose Category'
exports.contentLocation ='Location'
exports.locationPlaceholder ='Enter Location'
exports.contentKeyword ='Keywords'
exports.keywordPlaceholder ='Add your keywords'
exports.chipData = [
  { key: 0, label: 'Angular' },
  { key: 1, label: 'jQuery' },
  { key: 2, label: 'Polymer' },
  { key: 3, label: 'React' },
  { key: 4, label: 'Vue.js' },
];
exports.setThePriceLabel = 'SET THE PRICE'
exports.setThePriceDescription = 'Select your license type to get personalised pricing.'
exports.selectPriceLabel = 'Select Price'
exports.keywordLabel = 'Keyword Suggestions'
exports.nsfwTitle = 'NSFW content'
exports.nsfwDescription = 'This photo contain nudity, sexually explicit or suggestive content.'
exports.submitBtn = "Submit"
exports.approveBtn= "Approve"
exports.rejectBtn = "Reject";
exports.rejectionContent = "Rejection Reason";
exports.contentApiContentType = "application/json";
exports.rejectPlaceholder = "Add Your Rejection";
exports.reject = "Reject"
exports.apiMethodTypeGet = "GET";
exports.putMethod= "PUT";
exports.postMethod = "POST";
exports.sizePlaceholder = "Small (1-500 employees)"
exports.choosecategory= "Choose category";
exports.contentApiEndPoint = "bx_block_review_and_approval/list_contributor_content";
exports.digitalText="Digital/Web"
exports.totalContentApiEndPoint = "bx_block_review_and_approval/total_count";
exports.categoryApiEndPoint = "bx_block_categories/categories";
exports.contentDetailsApiEndPoint = "bx_block_review_and_approval/show_content";
exports.deleteDialogTitle = "ARE YOU SURE?";
exports.deleteContent= "You want to Delete this Catalogue, Please confirm";
exports.approveContent = "You want to Approve this Catalogue, Please confirm";
exports.rejectCatalogueEndPoint = "bx_block_review_and_approval/reject"
exports.cancelBtnLabel = "Cancel";
exports.deleteBtnLabel = "Yes, I Confirm";
exports.deleteMethod = "DELETE";
exports.keywordSuggestion="Keyword suggestions ";
exports.auto="(auto applied)";
exports.deleteContentEndPoint = "bx_block_review_and_approval/delete_content/:id";
exports.allCountryEndPoints = "bx_block_catalogue/catalogues/list_countries";
exports.licenseEndPoints = "bx_block_catalogue/catalogues/get_license_type"
exports.sizeEndPoint = "bx_block_catalogue/catalogues/get_size";
exports.formatEndPoint = "bx_block_catalogue/catalogues/get_format";
exports.approveCatalogueEndPoint = "bx_block_review_and_approval/update_content";
exports.saveCatalogueEndPoint = "bx_block_review_and_approval/saved_content";
exports.rejectionDesc= "Rejection Description";
exports.sortType = [
  {label:"Sort By",value:'Sort By'},
  {label:"New Date",value:'desc_date'},
   {label:"Old Date",value:"asc_date"},
   {label:"High Price",value:"high_price"},
   {label:"Low Price",value:"low_price"},
   ];
// Customizable Area End
