import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomTypography from "../CustomTypography/CustomTypography.web";
import customTheme from "../Theme/Theme.web";

const styles = (theme: Theme) =>
  createStyles({
    accordion: {
      marginBottom: theme.spacing(2),
      backgroundColor: customTheme.palette.white.main,
    },
    accordionSummary: {
      backgroundColor: customTheme.palette.white.main,
    },
  });

interface CustomAccordionProps {
  classes: Record<string, string>;
  title: string;
  content: string;
  expanded:any;
  AccordionSummaryVariant?: any;
  AccordionDetailsVariant?: any;
  handleExpand:()=>void;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  classes,
  title,
  content,
  expanded,
  handleExpand,
  AccordionSummaryVariant,
  AccordionDetailsVariant
}) => {

  return (
    <Accordion
      className={classes.accordion}
      expanded={expanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <CustomTypography component={AccordionSummaryVariant}>{title}</CustomTypography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography component={AccordionDetailsVariant}>{content}</CustomTypography>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(CustomAccordion);
