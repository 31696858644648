const customTheme: any = {
    palette: {
        primary: {
            main: '#000000',
            dark: '#0E0F17'
        },
        secondary: {
            main: '#73767A', 
            light: '#BFC2C3'
        },
        white: {
            main: '#FFFFFF',
            dark: '#E7EBEB',
            light: '#F3F4F4',
            grey:"#9D9D9D"
        },
        red:{
          main:"#DC2626",
          light: '#F00'
        },
        purple: {
            main: '#6200EA'
        },
        error: {
            main: '#CA2121', 
            dark: '#DC2626',
            light: '#F87171',
            lighter: '#FEE2E2'
        },
        warning: {
            main: '#A05703',
            dark: '#D97706',
            light: '#F59E0B',
            lighter: '#FEF3C7' 
        },
        success: {
            main: '#057A55', 
            dark: '#059669',
            light: '#34D399',
            lighter: '#D1FAE5'
        },
        blue:{
            main:"#3A82FF",
            secondary:"#0860FB"
        },
        disabled:{
            main:"#E7EBEB"
        },
        shadow:{
            main:"#CECECE"
        },
        grey:{
         main:"#949494",
         secondary:"#EBEBEB",
         primary:"#E7EBEB",
         light:"#BFC2C3"
        }
    }
}

export default customTheme;