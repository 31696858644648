import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData} from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    commentsData : any
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserCommentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCommentsDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      commentsData:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getCommentsDataCallId){
        this.setState({commentsData : responseJson.data})
      }
      console.log(apiRequestCallId, errorReponse, responseJson)
    // Customizable Area End
  }
}

  // Customizable Area Start
  async  componentDidMount(){
    this.handleFollowingData()
  }
  handleFollowingData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeAppliactionJson,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCommentsDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommentsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleTime = (updatedAt: string) => {
    const updatedDate:any = new Date(updatedAt);
const currentDate:any = new Date();

const timeDifference = currentDate - updatedDate;
const secondsDifference = Math.floor(timeDifference / 1000);
const minutesDifference = Math.floor(timeDifference / (1000 * 60));
const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

if (daysDifference > 0) {
  return `${daysDifference} days`
} else if (hoursDifference > 0) {
  return  `${hoursDifference} hours`
} else if (minutesDifference > 0) {
    return `${minutesDifference} minutes`
} else {
    return `${secondsDifference} seconds`
}
  }
  // Customizable Area End
}
