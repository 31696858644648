Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "No messages found.";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.title="New Quest!check out our lates";
exports.title1="Trending collections.";
exports.time="5 mins ago";
exports.button="See All Notifications";
exports.buttonClear="Clear All Notifications";
exports.Notifications="Notifications";
// Customizable Area End