import React from "react";
// Customizable Area Start
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {Box, Breadcrumbs} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InteractiveFaqSectionController, {
    Props
} from "./InteractiveFaqSectionController";
export const configJSON = require("./config");
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomLink from "../../../components/src/DesignSystem/Link/Link.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({});

// Customizable Area End

export default class InteractiveFaqAnswer extends InteractiveFaqSectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const QuestionAnswerdata = this.state.InteractiveFaqQuestionType && this.state.InteractiveFaqQuestionType.map(i => i.attributes.questions).flat().filter(i => i.question_id == this.state.QuestionId)
        return (
            <>
                <NavigationMenu
                    showHeardNav={true}
                    contributorLogin={false}
                    contributorContent={false}
                    navigation={undefined}
                    goToLanding={this.GoLandingPage}
                    id={""}
                />
                <ThemeProvider theme={theme}>
                    <Box>
                        <div style={webStyle.breadcrumbBlock}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <CustomLink url="/" component={"breadcrumbText"}>
                                    Home
                                </CustomLink>
                                <CustomLink component={"breadcrumbText"} url={`/FAQInteractives`}>
                                    FAQs
                                </CustomLink>
                                <CustomTypography variant="primary" component="body10">Answer</CustomTypography>
                            </Breadcrumbs>
                        </div>
                    </Box>
                    <Box style={webStyle.FaqAnswerSection}>
                        <Box style={webStyle.FaqAnswerInner}>
                        <Box style={webStyle.textAlign}>
                        <CustomTypography component={"FaqHeading"}>FAQs</CustomTypography>
                        </Box>
                    <Box>
                        {QuestionAnswerdata && QuestionAnswerdata.map(answer => {
                           return (
                                <Box>
                                    <CustomTypography component={"FaqQuestionHeading"}>
                                        {answer.title}
                                    </CustomTypography>
                                    <div style={webStyle.answerBlock}dangerouslySetInnerHTML={{ __html: answer.description}}/>
                                </Box>
                            )
                        })}
                    </Box>
                    <Box>
                        {this.state.SimilarQuestion && this.state.SimilarQuestion.map((i:any)=>{
                            return(
                                <CustomLink component="body5" url={`/InteractiveFaqAnswer/${i.id}`} >
                                    <CustomTypography component="linkText">
                                      {i.title}
                                    </CustomTypography>
                                  </CustomLink>
                            )
                        })}
                    </Box>
                        </Box>
                   
                    </Box>
                </ThemeProvider>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyle = {
    breadcrumbBlock: {
        margin: "25px 25px 0px"
    },
    breadcrumbText: {
        color: "#73767A",
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: "Outfit",
        underline: "none"
    },
    FaqAnswerSection:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center", 
    },
    FaqAnswerInner:{
        maxWidth:"883px",
        width:"100%", 
    },
    textAlign:{
        textAlign:"center" as "center"
    },
    answerBlock:{
        fontFamily:"Outfit",
        lineHeight:"20px",
        fontSize:"16px"
    }
}

// Customizable Area
