// import React from "react";

// import {
//   Container,
//   Box,
//   Input,
//   Button,
//   InputLabel,
//   Typography,
//   InputAdornment,
//   IconButton,
//   // Customizable Area Start
//   // Customizable Area End
// } from "@material-ui/core";

// // Customizable Area Start
// import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Visibility from "@material-ui/icons/Visibility";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#fff",
//       contrastText: "#fff",
//     },
//   },
//   typography: {
//     h6: {
//       fontWeight: 500,
//     },
//     subtitle1: {
//       margin: "20px 0px",
//     },
//   },
// });
// // Customizable Area End

// import WatermarkController, {
//   Props,
//   configJSON,
// } from "./WatermarkController";

// export default class Watermark extends WatermarkController {
//   constructor(props: Props) {
//     super(props);
//     // Customizable Area Start
//     // Customizable Area End
//   }

// Customizable Area Start

import React from "react";
import { createTheme } from "@material-ui/core/styles";
import {
    Box,
    Avatar, Grid, styled, ThemeProvider,Dialog, DialogContent, DialogActions
} from "@material-ui/core";
import WatermarkController, { Props } from "./WatermarkController.web";
import {
    RightCropIcon,
    leftCropIcon,
    uploadImageIcon,
    watermark,
   error
} from "./assets";
import CloseIcon from '@material-ui/icons/Close';
export const configJSON = require("./config");
import { Resizable } from "react-resizable";
import 'react-resizable/css/styles.css';
import Label from "../../../components/src/DesignSystem/Label/Label.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import { sideBarBottomNav, sideBarListItem } from "../../../blocks/navigationmenu/src/NavigationMenuController";
import Input from "../../../components/src/DesignSystem/Input/Input.web";
import CustomSnackBar from "../../../components/src/DesignSystem/CustomSnackBar/CustomSnackBar";

const theme = createTheme({});


export default class Watermark extends  WatermarkController {
  constructor(props: Props) {
    super(props);
 
  }
  render() {

    return (
      <>
       <NavigationMenu ModeratorLogin={true} contributorLogin={true} contributorContent={false} data-test-id="goToUpload" navigation={undefined} id={""} />
                <SideBar id="Test-id" navigation={this.props.navigation} sideBarBottomNav={sideBarBottomNav} sideBarListItem={sideBarListItem}>
                    <ThemeProvider theme={theme}>
                        <Box>
                            <CustomGrid container spacing={2}>
                                <Grid container spacing={2} style={webStyle.mainContainer}>
                                    <Grid style={webStyle.watermarkHead}>
                                        <Grid item xs={12} lg={6} style={webStyle.uploadHead}>
                                            Add Watermark To Photo & Image Files
                                        </Grid>
                                        {this.state.selectedFile && this.state.selectedFile.length > 0 && !this.state.applyClick ? (
                                            <Grid item style={webStyle.cropImageBlock}>
                                                <span  ><img src={RightCropIcon} alt="Right Crop Icon" data-test-id = "rightRotationBtn" onClick={this.handleRightRotation}  /></span>
                                                <span  ><img src={leftCropIcon} alt="Left Crop Icon" data-test-id = "leftRotationBtn" onClick={this.handleLeftRotation} /></span>
                                            </Grid>
                                        ) : null}
                                    </Grid>

                                    {this.state.selectedFile == null && 
                                    <CustomGridContainer container onDrop={this.handleDrop} data-test-id="dragDrop"
                                    onDragOver={this.handleDragOver}
                                     >
                                        <Avatar src={`${uploadImageIcon}`} style={webStyle.showIcon} />
                                        <Grid item style={{ ...webStyle.dragContent }} >{configJSON.dragContent}</Grid>
                                        <CustomTypography component="text_none">{configJSON.or}</CustomTypography>
                                        <Label htmlFor='fileInput'>
                                            <div style={webStyle.btnFileWidth}>

                                            <Grid data-test-id='handleUploadClick' style={webStyle.selectFilesBtn} onClick={this.handleUploadClick}>{configJSON.selectFiles}</Grid>
                                            </div>
                                        </Label>
                                        <CustomInput>
                                            <Input
                                                type='file'
                                                id='fileInput'
                                                ref={this.inputRef}
                                                style={webStyle.fileInput}
                                                data-test-id='handleFileInputChange'
                                                onChange={this.handleFileInputChange}
                                                multiple
                                            />
                                        </CustomInput>
                                        <CustomTypography component="text_none">{configJSON.watermarkImageCondition}</CustomTypography>
                                    </CustomGridContainer>}
                                    {(this.state.selectedFile  && this.state.selectedFile.length>1 && !this.state.applyClick) && (
                                        <CustomGridWatermark style={webStyle.uploadWaterMark}>
                                            <Grid style={webStyle.BtnBlock} >
                                                <Grid >
                                                    <div className="watermark">
                                                     <img  src={`${watermark}`} alt="Watermark" style={webStyle.watermark_img}/> 
                                                     <div className="crop_img"
                                                    style={{
                                                    width: this.state.size.width,
                                                    height: this.state.size.height,
                                                    }}
                                                >
                                                    <Resizable
                                                    width={this.state.size.width}
                                                    height={this.state.size.height}
                                                    onResize={this.handleResize}
                                                    data-test-id="resizebtn"
                                                    resizeHandles={['nw', 'ne', 'se', 'sw']}
                                                    minConstraints={[50, 50]}
                                                    maxConstraints={[500, 500]}
                                                    >
                                                    <div
                                                        style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        border: '2px dashed #fff',
                                                        position: 'absolute',
                                                        boxSizing: 'border-box',
                                                        transform: `rotate(${this.state.rotation}deg)`
                                                        }}
                                                    >
                                                        <img
                                                        ref={this.imageRef}
                                                        src={this.state.selectedFile}
                                                        style={{ width: '100%', height: '100%'}}
                                                        alt="Rotated and Resized Image"
                                                        crossOrigin="anonymous"
                                                        />
                                                    </div>
                                                    </Resizable>
                                                </div>
                                                    </div>
                                                </Grid>
                                                <Grid style={webStyle.UploadBtnBLock}>
                                                    <div style={webStyle.btnWidth}>
                                                        <CustomButton variant="secondary" size="large" fullWidth='fullWidth' data-test-id="EditCancelBtn" onClick={this.handleEditCancel}>
                                                            Cancel
                                                        </CustomButton>
                                                    </div>
                                                    <div style={webStyle.btnWidth}>
                                                        <CustomButton variant="primary" size="large" fullWidth='fullWidth' data-test-id="applyClickBtn" onClick={this.handleApplyClick}>
                                                            Apply
                                                        </CustomButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CustomGridWatermark>
                                    )}
                                    {(this.state.selectedFile && this.state.selectedFile.length > 1 && this.state.applyClick) && (
                                        <CustomGridWatermark style={webStyle.uploadWaterMark}>
                                            <Grid style={webStyle.BtnBlock}>
                                                <Grid className="watermark">
                                                <img src={`${watermark}`} alt="Watermark" style={webStyle.watermark_img}/>
                                               <span className="crop_img">
                                                <img src={`${this.state.updatedImage}`}/>
                                                </span> 
                                                </Grid>
                                                <Grid style={webStyle.UploadBtnBLock}>
                                                    <div style={webStyle.btnWidth}>
                                                        <CustomButton variant="secondary" size="large" fullWidth='fullWidth' data-test-id="cancelSubmitBTn" onClick={this.handleSubmitCancel}>
                                                            Cancel
                                                        </CustomButton>
                                                    </div>
                                                    <div style={webStyle.btnWidth}>
                                                        <CustomButton variant="primary" size="large" fullWidth='fullWidth' data-test-id="submitBtn" onClick={this.handleSubmit}>
                                                            Submit
                                                        </CustomButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CustomGridWatermark>
                                    )}
                                </Grid>
                            </CustomGrid>
                        </Box>
                        <Dialog open={this.state.errorMess} PaperProps={{
                  style: {
                    padding: 20,
                  },
                }} maxWidth="md" data-test-id="handlePreviewClose" onClose={this.handleInValidContent}>
                  <DialogActions>
                    <CloseIcon onClick={this.handleInValidContent} />
                  </DialogActions>
                  <DialogContent style={webStyle.flexDisplay} >
                    <img src={error} />
                    <CustomTypography component="red">{configJSON.errorContent}</CustomTypography>
                    <div style={webStyle.btnWidths}>
                    <CustomButton variant="secondary" data-test-id="closeBtn" fullWidth='fullWidth' size="large" onClick={this.handleInValidContent}>{configJSON.retryBtn}</CustomButton>
                    </div>
                  </DialogContent>
                </Dialog>
                <CustomSnackBar
          open={this.state.showSnackbar}
          crosserror
          onClose={this.handleCloseSnackBar}
          title={this.state.fileUploadedMess ? 'Image Uploaded successfully':"Session expired please login"}
          data-test-id="backdrop"
          horizontal="center"
          variant={this.state.fileUploadedMess ? 'success':"error"}
        />
                    </ThemeProvider>
                </SideBar>
      </>
    );

  }
}

const webStyle: any = {
 
    MainWrapperHeading: {
        padding: "24px"
    },
    mainContainer: {
        width: "1360px",
        gap: "32px"
    },
    uploadHead: {
        fontSize: "24px",
        fontWeight: 400,
        fontFamily: "lemonMilk",
        textTransform: "Uppercase",
        marginTop: "50px"
    },
    dragContent: {
        fontSize: "32px",
        fontWeight: 400,
        textTransform: "Uppercase",
        fontFamily: "lemonMilk",
    },
    watermarkHead: {
        width: "100%",
        display: "flex",
    },
    cropImageBlock: {
        marginTop: "50px",
        display: "flex",
        gap: "20px"
    },
    fileInput: { display: 'none' },
    showIcon: {
        width: "98px",
        height: "98px"
    },
    selectFilesBtn: {
        color: "white",
        backgroundColor: "black",
        textTransform: "uppercase",
        fontSize: "14px",
        padding: "16px 30px",
        textAlign: "center",
        cursor: "pointer"
    },
    btnWidth: {
        width: "126px"
    },
    btnWidths:{
        width: "200px"  
    },
    uploadWaterMark: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    UploadBtnBLock: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "24px",
        marginTop: "32px",
        width:"100%"
    },
    watermark_img:{
        maxWidth:"559px",
        width:"100%",
        height:"514px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
        
    },
    btnFileWidth:{
        width:"224px",
        height:"48px"
    },
    BtnBlock:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
    },
    flexDisplay: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '50px',
        flexDirection: "column"
      },
  };
  const CustomInput = styled(Box)({
    '& .MuiFormHelperText-contained': {
        marginLeft: '0px'
    },
    display: "none"
  })
  const CustomGrid = styled(Grid)({
      display: "flex",
    marginBottom: "2rem",
    justifyContent: "center",
    '@media (min-width: 320px)': {
        padding: '0px 50px',
    },
  })
  const CustomGridContainer = styled(Grid)({
      height: "auto",
      border: "2px dashed #73767A",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: '16px',
    backgroundColor: "#F6F6F6",
    padding: "40px 0",
    '@media (max-width: 768px)': {
        padding: '40px 20px',
    },
  })
  const CustomGridWatermark = styled(Grid)(
    {
        "& .watermark": {
            position: "relative"
        },
        "& .crop_img": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        },
        "& .react-resizable-handle-ne":{
            top:"-8px",
            right:"-10px"
        },
        "& .react-resizable-handle-nw":{
            top:"-8px",
            left:"-10px"
        }
        , "& .react-resizable-handle-se":{
            bottom:"-8px",
            right:"-10px"
        },
        "& .react-resizable-handle-sw":{
            bottom:"-8px",
            left:"-10px"
        },
        "& .react-resizable-handle":{
            // top:"-10px",
            // right:"-10px",
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3C/svg%3E")`
        }
    }
  )

// Customizable Area End

//   render() {
//     return (
//       // Customizable Area Start
//       <ThemeProvider theme={theme}>
//         <Container maxWidth={"sm"}>
//           <Box sx={webStyle.mainWrapper}>
//             <Typography variant="h6">{configJSON.labelTitleText}</Typography>
//             <Typography variant="subtitle1" component="div">
//               {configJSON.labelBodyText}
//             </Typography>
//             <Box sx={webStyle.inputStyle}>
//               <InputLabel id="service-shop-name">
//                 This is the reviced value:{this.state.txtSavedValue}{" "}
//               </InputLabel>
//               <Input
//                 data-test-id={"txtInput"}
//                 type={this.state.enableField ? "password" : "text"}
//                 placeholder={configJSON.txtInputPlaceholder}
//                 fullWidth={true}
//                 disableUnderline={true}
//                 value={this.state.txtInputValue}
//                 onChange={(eData) => this.setInputValue(eData.target.value)}
//                 endAdornment={
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={this.setEnableField}
//                       edge="end"
//                     >
//                       {this.state.enableField ? (
//                         <Visibility />
//                       ) : (
//                         <VisibilityOff />
//                       )}
//                     </IconButton>
//                   </InputAdornment>
//                 }
//               />
//             </Box>
//             <Box
//               data-test-id="btnAddExample"
//               onClick={() => this.doButtonPressed()}
//               component="button"
//               sx={webStyle.buttonStyle}
//             >
//               <Button color={"primary"}>{configJSON.btnExampleTitle}</Button>
//             </Box>
//           </Box>
//         </Container>
//       </ThemeProvider>
//       // Customizable Area End
//     );
//   }
// }

// // Customizable Area Start
// const webStyle = {
//   mainWrapper: {
//     display: "flex",
//     fontFamily: "Roboto-Medium",
//     flexDirection: "column",
//     alignItems: "center",
//     paddingBottom: "30px",
//     background: "#fff",
//   },
//   inputStyle: {
//     borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
//     width: "100%",
//     height: "100px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
//   buttonStyle: {
//     width: "100%",
//     height: "45px",
//     marginTop: "40px",
//     border: "none",
//     backgroundColor: "rgb(98, 0, 238)",
//   },
// };
// // Customizable Area End
