// Customizable Area Start
import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomDivider from "../../../components/src/DesignSystem/Divider/Divider.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  sideBarContributoBottomNav,
  sideBarContributorListItem,
} from "../../../blocks/navigationmenu/src/NavigationMenuController";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import CustomDropDown from "../../../components/src/DesignSystem/CustomDropDown/CustomDropDown.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import TaxCenterUserInfoController from "./TaxCenterUserInfoController.web";
import Input from "../../../components/src/DesignSystem/Input/Input.web";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";

export const configJSON = require("./config");

// Customizable Area End
export class TaxCenterUserInfo extends TaxCenterUserInfoController {
  render() {
    // Customizable Area Start

    const { classes } = this.props;

    return (
      <>
        <NavigationMenu
          navigation={this.props.navigation}
          id={""}
          contributorLogin={true}
          data-test-id="showCollection"
        />

        <SideBar
          id=""
          navigation={this.props.navigation}
          sideBarBottomNav={sideBarContributoBottomNav}
          sideBarListItem={sideBarContributorListItem}
        >
          <Box className={classes.taxCenterUserInfoContainer}>
            <Box className={classes.taxCenterUserInfoHeading}>
              <CustomTypography component="h7">TAX CENTER</CustomTypography>
            </Box>

            <Box className={classes.taxCenterUserInfoForm}>
              <Formik
                initialValues={{
                  isUs: null,
                  contribute: null,

                  email: "",
                  address: "",
                  city: "",
                  zipCode: "",
                  province: "",
                  phone: "",
                }}
                validationSchema={Yup.object().shape({
                  isUs: Yup.boolean()
                    .nullable()
                    .required("Please select an option"),
                  contribute: Yup.boolean()
                    .nullable()
                    .required("Please select an option"),

                  email: Yup.string()
                    .email("Invalid email")
                    .required("Email is required"),

                  address: Yup.string().required("Address is required"),
                  city: Yup.string().required("City is required"),
                  zipCode: Yup.string()
                    .matches(/^\d{5}$/, "Zipcode must be exactly 5 digits")
                    .required("Zipcode is required"),
                  province: Yup.string().required("Province is required"),
                  phone: Yup.string()
                    .matches(
                      /^\d{10}$/,
                      "Phone number must be exactly 10 digits"
                    )
                    .required("Phone number is required"),
                })}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  console.log("onSubmit", values, actions);
                }}
                data-test-id="tax-center-form"
              >
                {({
                  values,
                  errors,
                  touched,

                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className={classes.taxCenterUserInfoHeading}>
                        <CustomTypography component="body5">
                          YOUR TAX PROFILE
                        </CustomTypography>

                        <Box className={classes.taxCenterUserInfoNew}>
                          <CustomTypography component="body1111">
                            <span className={classes.taxCenterUserInfoLink}>
                              Submit a new tax form
                            </span>
                          </CustomTypography>
                        </Box>
                      </Box>

                      <Box className={classes.taxCenterUserInfoFormInner}>
                        <Box className={classes.taxCenterUserInfoFormRow}>
                          <Box className={classes.taxCenterUserInfoFormLabel}>
                            <CustomTypography component="body1111">
                              For U.S. tax purposes, are you a U.S. person?
                            </CustomTypography>
                          </Box>

                          <FormControl
                            className={classes.taxCenterUserInfoFormControl}
                            error={Boolean(touched.isUs && errors.isUs)}
                          >
                            <Box className={classes.taxCenterUserInfoDropdown}>
                              <CustomDropDown
                                data-test-id="is-us-person-dropdown"
                                variant="dropdownContent"
                                endAdornment={<ExpandMore />}
                                options={this.options}
                                selectedItem={this.getOption(values.isUs)}
                                selectNewItem={(option) => {
                                  setFieldValue("isUs", option.value);
                                }}
                              />
                            </Box>

                            {Boolean(touched.isUs && errors.isUs) && (
                              <FormHelperText>{errors.isUs}</FormHelperText>
                            )}
                          </FormControl>
                        </Box>

                        <CustomDivider
                          orientation="vertical"
                          variant="greyPrimary"
                        />

                        <Box className={classes.taxCenterUserInfoFormRow}>
                          <Box className={classes.taxCenterUserInfoFormLabel}>
                            <CustomTypography component="body1111">
                              Are you contributing to Shutterstock as an
                              individual or as a business?
                            </CustomTypography>
                          </Box>

                          <FormControl
                            className={classes.taxCenterUserInfoFormControl}
                            error={Boolean(
                              touched.contribute && errors.contribute
                            )}
                          >
                            <Box className={classes.taxCenterUserInfoDropdown}>
                              <CustomDropDown
                                data-test-id="is-contribute-dropdown"
                                variant="dropdownContent"
                                endAdornment={<ExpandMore />}
                                options={this.options}
                                selectedItem={this.getOption(values.contribute)}
                                selectNewItem={(option) => {
                                  setFieldValue("contribute", option.value);
                                }}
                              />
                            </Box>

                            {Boolean(
                              touched.contribute && errors.contribute
                            ) && (
                              <FormHelperText>
                                {errors.contribute}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box className={classes.taxCenterUserInfoAddress}>
                        <Box className={classes.taxCenterUserInfoHeading}>
                          <CustomTypography component="body5">
                            YOUR ADDRESS
                          </CustomTypography>
                        </Box>

                        <Box
                          className={classes.taxCenterUserInfoFormAddressInner}
                        >
                          <Box className={classes.taxCenterUserInfoAddressForm}>
                            <Box
                              className={
                                classes.taxCenterUserInfoAddressFormMain
                              }
                            >
                              <Box
                                className={
                                  classes.taxCenterUserInfoAddressFormRow
                                }
                              >
                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormRowInner
                                  }
                                >
                                  <Input
                                    label="Mailing Address"
                                    labelFontFamily="Outfit"
                                    value={values.email}
                                    onChange={(e: any) => {
                                      setFieldValue("email", e.value);
                                    }}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    name="email"
                                    data-test-id="email-input"
                                    placeholder="Address 1"
                                  />
                                </Box>

                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormSide
                                  }
                                >
                                  <CustomTypography component="body1111">
                                    The address used on your Qstock account or
                                    you can{" "}
                                    <span
                                      className={classes.taxCenterUserInfoLink}
                                    >
                                      change the address on your account
                                      settings.
                                    </span>
                                  </CustomTypography>
                                </Box>
                              </Box>

                              <Box
                                className={
                                  classes.taxCenterUserInfoAddressFormRow
                                }
                              >
                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormRowInner
                                  }
                                >
                                  <Input
                                    label="Address line 2 (Apartment #, street #)"
                                    labelFontFamily="Outfit"
                                    value={values.address}
                                    onChange={(e: any) =>
                                      setFieldValue("address", e.value)
                                    }
                                    error={!!errors.address}
                                    helperText={errors.address}
                                    name="address"
                                    data-test-id="address-input"
                                    placeholder="Address 2"
                                  />
                                </Box>
                              </Box>

                              <Box
                                className={
                                  classes.taxCenterUserInfoAddressFormRow
                                }
                              >
                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormRowInner
                                  }
                                >
                                  <Input
                                    label="City"
                                    labelFontFamily="Outfit"
                                    value={values.city}
                                    onChange={(e: any) =>
                                      setFieldValue("city", e.value)
                                    }
                                    error={!!errors.city}
                                    helperText={errors.city}
                                    name="city"
                                    data-test-id="city-input"
                                    placeholder="Enter city name"
                                  />
                                </Box>
                              </Box>

                              <Box
                                className={
                                  classes.taxCenterUserInfoAddressFormRow
                                }
                              >
                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormRowInner
                                  }
                                >
                                  <Input
                                    label="Postal/Zip code"
                                    labelFontFamily="Outfit"
                                    value={values.zipCode}
                                    onChange={(e: any) =>
                                      setFieldValue("zipCode", e.value)
                                    }
                                    error={!!errors.zipCode}
                                    helperText={errors.zipCode}
                                    name="zipCode"
                                    data-test-id="zip-code-input"
                                    placeholder="440067"
                                  />
                                </Box>
                              </Box>

                              <Box
                                className={
                                  classes.taxCenterUserInfoAddressFormRow
                                }
                              >
                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormRowInner
                                  }
                                >
                                  <Input
                                    label="Province"
                                    labelFontFamily="Outfit"
                                    value={values.province}
                                    onChange={(e: any) =>
                                      setFieldValue("province", e.value)
                                    }
                                    error={!!errors.province}
                                    helperText={errors.province}
                                    name="province"
                                    placeholder="Maharashtra"
                                    data-test-id="province-input"
                                  />
                                </Box>
                              </Box>

                              <Box
                                className={
                                  classes.taxCenterUserInfoAddressFormRow
                                }
                              >
                                <Box
                                  className={
                                    classes.taxCenterUserInfoAddressFormRowInner
                                  }
                                >
                                  <Input
                                    label="Phone"
                                    labelFontFamily="Outfit"
                                    value={values.phone}
                                    onChange={(e: any) =>
                                      setFieldValue("phone", e.value)
                                    }
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    name="phone"
                                    placeholder="+91 7980995678"
                                    data-test-id="phone-input"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className={classes.taxCenterUserInfoBottom}>
                        <Box className={classes.taxCenterUserInfoCancel}>
                          <CustomButton
                            variant="secondaryWithoutBorder"
                            data-test-id="cancel-btn"
                            size="large"
                            type="submit"
                          >
                            CANCEL
                          </CustomButton>
                        </Box>

                        <Box className={classes.taxCenterUserInfoSubmit}>
                          <CustomButton
                            data-test-id="submit-btn"
                            size="large"
                            type="submit"
                          >
                            SUBMIT
                          </CustomButton>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Box>

          <Footer userType={"contributor"} navigation={""} />
        </SideBar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  taxCenterUserInfoContainer: {
    padding: "28px 16px",
  },

  taxCenterUserInfoHeading: {
    padding: 8,
    display: "flex",
    alignItems: "center",
  },

  taxCenterUserInfoForm: {
    marginTop: 20,
  },

  taxCenterUserInfoFormInner: {
    border: `1px solid ${customTheme.palette.secondary.light}`,
    padding: 12,
  },

  taxCenterUserInfoFormAddressInner: {
    border: `1px solid ${customTheme.palette.secondary.light}`,
    padding: "36px 12px 52px",
  },

  taxCenterUserInfoFormRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 20,
  },

  taxCenterUserInfoFormControl: {
    maxWidth: 152,
    width: "100%",
  },

  taxCenterUserInfoFormDivider: {
    margin: "28px 0",
  },

  taxCenterUserInfoFormLabel: {
    display: "flex",
    alignItems: "center",
    maxWidth: 487,
    width: "100%",
  },

  taxCenterUserInfoDropdown: {
    maxWidth: 152,

    "& > div": {
      border: `1px solid ${customTheme.palette.primary.main}`,
      backgroundColor: customTheme.palette.white.main,
      padding: "11px 16px",
      boxSizing: "border-box",

      "& .label": {
        fontSize: "14px !important",
      },
    },

    "& .dropdownCss": {
      textTransform: "capitalize !important",
      justifyContent: "space-between !important",
    },
  },

  taxCenterUserInfoAddressForm: {
    display: "flex",
    flexWrap: "wrap",
  },

  taxCenterUserInfoAddressFormMain: {
    width: "100%",
  },

  taxCenterUserInfoAddressFormSide: {
    maxWidth: 390,
    width: "100%",
    marginTop: 42,
  },

  taxCenterUserInfoAddressFormRow: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 30,

    "& + &": {
      marginTop: 16,
    },

    "& .label": {
      textTransform: "initial !important",
      fontFamily: "Outfit !important",
      fontSize: "18px !important",
    },

    "& input": {
      "&::placeholder": {
        fontFamily: "Outfit !important",
        fontSize: "16px !important",
      },
    },
  },

  taxCenterUserInfoAddressFormRowInner: {
    maxWidth: 831,
    width: "100%",
  },

  taxCenterUserInfoSubmit: {
    "& button": {
      background: `${customTheme.palette.secondary.main} !important`,
      width: 159,
      border: "none !important",
    },
  },

  taxCenterUserInfoCancel: {
    marginLeft: 12,

    "& button": {
      width: 159,
      color: `${customTheme.palette.blue.main} !important`,
    },
  },

  taxCenterUserInfoBottom: {
    marginTop: 32,
    display: "flex",
    justifyContent: "flex-end",
  },

  taxCenterUserInfoLink: {
    cursor: "pointer",
    color: customTheme.palette.blue.main,
  },

  taxCenterUserInfoNew: {
    marginLeft: "auto",
  },

  taxCenterUserInfoAddress: {
    marginTop: 32,
  },
});
// Customizable Area End

export default withStyles(styles)(TaxCenterUserInfo);
