import React from "react";
// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomCard from "../../../components/src/DesignSystem/CustomCard/CustomCard.web";
import Divider from "../../../components/src/DesignSystem/Divider/Divider.web";
import { facebookgrey, linkdingrey, twittergrey } from "./assets";

interface Props {
  handingListData: any;
  title: any;
}
// Customizable Area End

class MyreferralsReferredcontributors extends React.Component<Props> {
  render() {
    // Customizable Area Start
    return (
      <CustomCard variant="variant3">
        <Main>
          <div style={webStyles.main}>
            {this.props.handingListData &&
              this.props.handingListData.map((item: any) => (
                <CustomTypography key={item.id} component="body22">
                  {item.title}
                </CustomTypography>
              ))}
          </div>
          <Divider variant="greyPrimary"></Divider>
          <div style={webStyles.title}>
            <CustomTypography component="h7">{this.props.title}</CustomTypography>
            <SocialIcon>
              <img src={facebookgrey} alt="icon" />
              <img src={twittergrey} alt="icon" />
              <img src={linkdingrey} alt="icon" />
            </SocialIcon>
          </div>
        </Main>
      </CustomCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles: any = {
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  
  title: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "80%",
    gap: "20px",
  },
};

const Main = styled("div")({
  padding: "16px 16px 8px 16px",
  minHeight: 333,
});

const SocialIcon = styled(Box)({
  display: "flex",
  "& > img": {
    paddingRight: "20px",
  },
});

// Customizable Area End

export default MyreferralsReferredcontributors;
