Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.postMethod = 'POST'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.getHashtagSuggestionEndPoints="bx_block_hashtags/hashtags/hashtag_suggestions?hashtag="
exports.errorTitle = 'Error'
exports.getHastagsFilterApiEndPoint="/bx_block_hashtags/hashtags/search?keyword="
exports.getRecommendationApiEndPoint = 'bx_block_advanced_search/search/search_recommendation?title='
exports.getFiltersApiEndPoint = 'bx_block_advanced_search/search/filter_type'
exports.getSearchReasultsApiEndPoint = "bx_block_landingpage2/landingpages/filter_search"
exports.createCollectionsAPiEndPoint = "bx_block_photo_library/collections/private_collection";
exports.getBase64APiEndPoint = "bx_block_watermark/download_water_mark_image";
// Customizable Area End
