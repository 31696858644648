export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const edit = require("../assets/edit.png");
export const profileIcon = require("../assets/profileicon.png");
export const profile = require("../assets/profile.png");
export const upload = require("../assets/upload.png");
export const sad = require("../assets/sad.png");
export const errorIcon = require("../assets/erroricon.png");
export const SearchIcon = require("../assets/search_icon.svg");
export const downArrow = require("../assets/down_arrow.svg");
export const DeleteIcon=require("../assets/delete.png");
export const EditIcon=require("../assets/editIcon.png");
export const CheckIcon=require("../assets/view_shape.png");
export const Flower= require("../assets/flower.png");
export const clock= require("../assets/clock.png");
export const location= require("../assets/location.png");
export const callender= require("../assets/callender.png")
export const search= require("../assets/searchIcon.png");
export const profileIcons= require("../assets/profileIcons.png")
export const follow= require("../assets/follow.png")
export const more= require("../assets/more.png")
export const flower= require("../assets/flower.png")
export const laptop= require("../assets/laptop.png")
export const girl= require("../assets/girl.png")
export const tree= require("../assets/tree.png")
export const wave= require("../assets/wave.png")
export const arrow= require("../assets/arrow.png")
export const setDot= require("../assets/setDot.png")










