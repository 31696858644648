import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomCard from "../../../components/src/DesignSystem/CustomCard/CustomCard.web";
import Divider from "../../../components/src/DesignSystem/Divider/Divider.web";

interface Props {
  heading: any;
  subHeading: any;
  CustomerCount: any;
  ContributorCount: any;
}
// Customizable Area End

class MyreferralsSummary extends React.Component<Props> {
  render() {
    // Customizable Area Start
    return (
      <CustomCard variant="variant3">
        <Main>
          <div style={webStyles.main}>
            <CustomTypography component="header2">
              {this.props.heading}
            </CustomTypography>
            <CustomTypography component="header2">
              {this.props.subHeading}
            </CustomTypography>
          </div>
          <Divider variant="greyPrimary"></Divider>
          <div style={webStyles.main1}>
            <CustomTypography component="body22">Contributor</CustomTypography>
            <CustomTypography component="body22">
              {this.props.subHeading}
            </CustomTypography>
          </div>
          <div style={webStyles.main}>
            <CustomTypography component="body22">Customer</CustomTypography>
            <CustomTypography component="body22">
              {this.props.subHeading}
            </CustomTypography>
          </div>
        </Main>
      </CustomCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles: any = {
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  main1: {
    display: "flex",
    justifyContent: "space-between",
    marginTop:20,
    marginBottom:16
  },
};

const Main = styled("div")({
  padding: "16px 16px 8px 16px",
  "& > div": {
    marginBottom: "24px",
  },
});

// Customizable Area End

export default MyreferralsSummary;
