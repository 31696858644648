import React from "react";
// Customizable Area Start
import EarningSummaryController, { Props } from "./EarningSummaryController";
import { Box, styled } from "@material-ui/core";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  sideBarContributorListItem,
  sideBarContributoBottomNav,
} from "../../../blocks/navigationmenu/src/NavigationMenuController";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import Divider from "../../../components/src/DesignSystem/Divider/Divider.web";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
import CustomDropDown from "../../../components/src/DesignSystem/CustomDropDown/CustomDropDown.web";
import { configJSON } from "./Paymentadmin2Controller";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { doubleTickIcon, upload } from "./assets";
import CustomCard from "../../../components/src/DesignSystem/CustomCard/CustomCard.web";
import CustomPagination from "../../../components/src/DesignSystem/CustomPagination/CustomPagination.web";
// Customizable Area End

class EarningSummary extends EarningSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu
          navigation={this.props.navigation}
          id={""}
          contributorLogin={true}
          data-test-id="showCollection"
        />
        <SideBar
          id=""
          navigation={this.props.navigation}
          sideBarBottomNav={sideBarContributoBottomNav}
          sideBarListItem={sideBarContributorListItem}
        >
          <CustonContainer>
            <div style={webStyles.main}>
              <div style={webStyles.headerMargin}>
                <CustomTypography component="h7">
                  earning summary
                </CustomTypography>
              </div>
              <CustomTypography component="body11">
                Total earnings
              </CustomTypography>
              <CustomTypography component="body11500">
                <div style={webStyles.dollar}>$0.00</div>
              </CustomTypography>
              <div style={webStyles.divider}>
                <Divider variant="vertical"></Divider>
              </div>
              <CustomTypography component="body11">
                Total downloads
              </CustomTypography>
              <CustomTypography component="body11500">
                <div style={webStyles.dollar}>0</div>
              </CustomTypography>
              <div style={webStyles.divider}>
                <Divider variant="vertical"></Divider>
              </div>
              <CustomTypography component="body11">
                Referral earnings
              </CustomTypography>
              <CustomTypography component="body11500">
                <div style={webStyles.dollar}>$0.00</div>
              </CustomTypography>
              <div style={webStyles.divider}>
                <Divider variant="vertical"></Divider>
              </div>
              <CustomTypography component="body11">
                Total referrals
              </CustomTypography>
              <CustomTypography component="body11500">
                <div style={webStyles.dollar}>0</div>
              </CustomTypography>
            </div>
            <div>
              <ul style={webStyles.tableUserList}>
                {this.tabsData.map((i, index) => {
                  return (
                    <li
                      key={index}
                      style={
                        this.state.active === i.id
                          ? {
                              ...webStyles.tablelistItem,
                              ...webStyles.activeList,
                            }
                          : webStyles.tablelistItem
                      }
                      onClick={() => this.handleActive(i.id)}
                      data-test-id="handleActive"
                    >
                      {i.tab}
                    </li>
                  );
                })}
              </ul>
            </div>
            {this.state.active == 1 && (
              <>
                <div style={webStyles.monthHeading}>
                  <CustomTypography component="title2">
                    october 2023
                  </CustomTypography>
                </div>
                <div style={webStyles.maindDropDownDiv}>
                  <div style={webStyles.dropdown}>
                    <DropDownWrapper>
                      <CustomDropDown
                        options={configJSON.month}
                        selectNewItem={this.handleMonth}
                        selectedItem={{
                          value: this.state.selectedMonth,
                          label: this.state.selectedMonth,
                        }}
                        endAdornment={<ExpandMoreIcon />}
                        variant="dropdownBtnEarning"
                        fontVariant="earning"
                      />
                    </DropDownWrapper>
                    <DropDownWrapper>
                      <CustomDropDown
                        variant="dropdownBtnEarning"
                        options={configJSON.year}
                        fontVariant="earning"
                        selectNewItem={this.handleYear}
                        selectedItem={{
                          value: this.state.selectedYear,
                          label: this.state.selectedYear,
                        }}
                        endAdornment={<ExpandMoreIcon />}
                      />
                    </DropDownWrapper>
                  </div>
                  <div style={webStyles.uploadBtn}>
                    <img src={upload} />
                    <CustomTypography component="body16">
                      Export (CSV)
                    </CustomTypography>
                  </div>
                </div>
                <div style={webStyles.mainCard}>
                  <CustomCard variant="variant3">
                    <div style={webStyles.card}>
                      <CustomTypography component="title2">
                        There are no earnings for this month
                      </CustomTypography>
                    </div>
                  </CustomCard>
                </div>
              </>
            )}
             {this.state.active == 2 && (
              <>
                <div style={webStyles.main}>
                  <div style={webStyles.header}>
                    <CustomTypography component="outfitBody6">
                      Subscriptions
                    </CustomTypography>
                    <CustomTypography component="outfitBody6">
                      <div style={webStyles.dollar1}>{"(0)"}</div>
                    </CustomTypography>
                  </div>
                  <CustomTypography variant="blue" component="outfitBody6">
                    On demand
                  </CustomTypography>
                  <CustomTypography variant="blue" component="outfitBody6">
                    <div style={webStyles.dollar1}>{"(0)"}</div>
                  </CustomTypography>
                  <div style={webStyles.divider}>
                    <Divider variant="vertical"></Divider>
                  </div>
                  <CustomTypography variant="blue" component="outfitBody6">
                    Enhanced
                  </CustomTypography>
                  <CustomTypography variant="blue" component="outfitBody6">
                    <div style={webStyles.dollar1}>{"(0)"}</div>
                  </CustomTypography>
                  <div style={webStyles.divider}>
                    <Divider variant="vertical"></Divider>
                  </div>
                  <CustomTypography variant="blue" component="outfitBody6">
                    Single & others
                  </CustomTypography>
                  <CustomTypography variant="blue" component="outfitBody6">
                    <div style={webStyles.dollar1}>{"(0)"}</div>
                  </CustomTypography>
                  <div style={webStyles.divider}>
                    <Divider variant="vertical"></Divider>
                  </div>
                  <CustomTypography variant="blue" component="outfitBody6">
                    Cart sales
                  </CustomTypography>
                  <CustomTypography variant="blue" component="outfitBody6">
                    <div style={webStyles.dollar1}>{"(0)"}</div>
                  </CustomTypography>
                  <div style={webStyles.divider}>
                    <Divider variant="vertical"></Divider>
                  </div>
                  <CustomTypography variant="blue" component="outfitBody6">
                    Clip packes
                  </CustomTypography>
                  <CustomTypography variant="blue" component="outfitBody6">
                    <div style={webStyles.dollar1}>{"(0)"}</div>
                  </CustomTypography>
                  <div style={webStyles.divider}>
                    <Divider variant="vertical"></Divider>
                  </div>
                  <CustomTypography variant="blue" component="outfitBody6">
                    Enhanced video
                  </CustomTypography>
                  <CustomTypography variant="blue" component="outfitBody6">
                    <div style={webStyles.dollar1}>{"(0)"}</div>
                  </CustomTypography>
                  <div style={webStyles.divider}>
                    <Divider variant="vertical"></Divider>
                  </div>
                </div>
                <div style={webStyles.mainCard}>
                  <CustomCard variant="variant3">
                    <div style={webStyles.card}>
                      <CustomTypography component="title2">
                        There is no information to display
                      </CustomTypography>
                    </div>
                  </CustomCard>
                </div>
                <div style={webStyles.pagination}>
                  <CustomPagination></CustomPagination>
                </div>
              </>
            )}
            {this.state.active == 3 && (
              <>
                <div style={webStyles.mainCard}>
                  <TopBanner>
                    <div style={webStyles.headerBanner}>
                      <img src={doubleTickIcon} alt="" style={webStyles.img} />
                    </div>
                    <div style={webStyles.headerBannerRightSection}>
                      <CustomTypography variant="primary" component="body10">
                        When our systems detect fraudulent transactions or other
                        illegal activity, we rescind rights to fraudulently
                        obtained assets and reverse related transactions. If
                        your assets are affected, corrections may need to be
                        made to your account, including earnings adjustments and
                        corrections to your download count.
                      </CustomTypography>
                    </div>
                  </TopBanner>
                  <CustomCard variant="variant3">
                    <div style={webStyles.card}>
                      <CustomTypography component="title2">
                        You don't have any transactions yet.
                      </CustomTypography>
                      <CustomTypography variant="primary" component="body10">
                        Try uploading some assets to get started.
                        <span style={webStyles.link}>{" " + "Upload"}</span>
                      </CustomTypography>
                    </div>
                  </CustomCard>
                </div>
                <div style={webStyles.pagination}>
                  <CustomPagination></CustomPagination>
                </div>
              </>
            )}
          </CustonContainer>
          <Footer userType={"contributor"} navigation={""} />
        </SideBar>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyles: any = {
  main: {
    display: "flex",
    alignItems: "center",
    marginBottom: 32,
    flexWrap: "wrap",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerMargin: {
    marginRight: "25px",
  },
  dollar: {
    margin: "0px 16px 0px 8px",
  },
  dollar1: {
    margin: "0px 12px 0px 8px",
  },
  divider: { marginRight: "16px" },
  tablelistItem: {
    fontSize: "16px",
    color: customTheme.palette.secondary.main,
    fontWeight: 400,
    listStyle: "none",
    fontFamily: "Outfit",
    padding: "10px",
  },
  activeList: {
    color: customTheme.palette.primary.main,
    borderBottom: `3px solid ${customTheme.palette.primary.main}`,
  },
  tableUserList: {
    display: "flex",
    alignItems: "center",
    gap: "72px",
    listStyle: "none",
    borderBottom: `1px solid ${customTheme.palette.secondary.light}`,
    padding: "0px",
  },
  dropdown: {
    display: "flex",
    width: "320px",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  monthHeading: {
    marginTop: "32px",
    marginBottom: "24px",
  },
  maindDropDownDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "wrap",
  },
  uploadBtn: {
    textAlign: "center",
  },
  card: {
    height: "287px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  mainCard: {
    marginBottom: "52px",
  },
  dropDownContent: {
    width: "143px",
  },
  headerBanner: {
    background: customTheme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "7rem",
    marginRight: "1rem",
  },
  headerBannerRightSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  img: {
    height: "1.5rem",
    width: "1.5rem",
  },
  link: {
    cursor: "pointer",
    color: customTheme.palette.blue.main,
  },
  pagination: {
    marginBottom: "26px",
    display: "flex",
    justifyContent: "end",
  },
};

const CustonContainer = styled(Box)({
  paddingTop: "1rem",
  marginLeft: "1rem",
  width: "calc(100% - 2.5rem)",
});

const TopBanner = styled(Box)({
  border: `1px solid ${customTheme.palette.secondary.light}`,
  height: "3.75rem",
  marginBottom: "1.25rem",
  display: "flex",
});
const DropDownWrapper = styled(Box)({
  width: "100%",
  "& .dropdownCss": {
    borderRadius: "0px !important",
    padding: "3px 12px !important",
  },
  "& .dropdownContentBlock": {
    width: "100%",
    fontSize: "16px !important",
    fontWeight: "400",
    fontFamily: "Outfit",
    boxShadow: "unset",
    border: `1px solid ${customTheme.palette.white.dark}`,
    maxHeight: "300px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  "& .Mui-checked": {
    color: "blue !important",
    width: "18px !important",
    height: "18px !important",
    borderRadius: "0px !important",
  },
  "& .material-icons": {
    color: customTheme.palette.primary.main,
  },
});

// Customizable Area End

export default EarningSummary;
