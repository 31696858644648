import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData} from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
    closeCollectionModal:()=>void;
    collectionModal: any;
    openCollectionModal : (value:any) => void;
    showCollectionData : (value:any) => void;
    collectionData?:any;
    showCollectionApi: ()=>void;
    selectedContent:any;
    handleContentSelect: (value:any)=> void;
    handlePublishSelect: (value:any)=> void;
    handleSortSelect: (value:any)=> void;
    selectedPublish?:any;
    selectedUpdate?:any;
    searchValue?:any;
    handleSearchValue?: (value:any) => void;
    prevPage?: ()=>any;
    nextPage?: ()=>void;
    currentPage?:any;
    totalCount?:any;
    showSearchError?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  selectedButton: string,
  setVisibility:any,
  assetsModal:boolean,
  anchorEl: any,
  collectionID:string,
  showAlert:boolean,
  alertType:any,
  userSuccessAlert:string,
  collectionName:any,
  galleryData:any,
  editing: boolean;
  showDeleteDialog:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PhotoLibraryCollectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteCollectionCallID:string="";
  createCollectionCallID : string ="";
  changeStatusCallID : string = "";
  changeNameCallID : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      
      selectedButton: "image",
      setVisibility: "Public",
      assetsModal:false,
      anchorEl: null,
      collectionID:"",
      showAlert:false,
      alertType:"success",
      userSuccessAlert:"",
      collectionName:"",
      galleryData:null,
      editing: false,
      showDeleteDialog:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.deleteCollectionCallID){
      this.setDeleteCollectionData(responseJson);
    }
    if(apiRequestCallId === this.createCollectionCallID){
      this.setCreateCollectionApi(responseJson)
      
    }
    if(apiRequestCallId == this.changeStatusCallID){
      this.setChangeStatusApi(responseJson)
    }
    if(apiRequestCallId == this.changeNameCallID){
      this.setChangeNameApi(responseJson)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.props.showCollectionApi();
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleButtonClick = (buttonType: string) => {
    this.setState({ selectedButton: buttonType });
  };
  handleSetVisibility = (event:any) => {
    this.setState({setVisibility: event.target.value})
  }
  createCollection = () => {
    this.props.closeCollectionModal();
    const titlePattern = /^[a-zA-Z][a-zA-Z0-9_ -]*$/;
    if (!titlePattern.test(this.state.collectionName)) {
      this.setState({
        showAlert : true,
        userSuccessAlert:"Invalid Collection Name Format",
        alertType:"error"
      })
    } 
    else{
      this.createCollectionCallApi();
    }
  }
  handleCreateCollection = (e:any) => {
this.setState({collectionName:e.value})
  }
  createCollectionCallApi = async() => {
    const{setVisibility, collectionName, selectedButton} = this.state
    const visibility=setVisibility.toLowerCase()
    const token = await getStorageData("authContributorToken");
    const header = {
      token: token,
      "Content-Type": "application/json"
    };
    let httpBody:any = {
      "collection":
    {
        "collection_name":collectionName,
        "collection_type":selectedButton,
        "collection_subtype": `${visibility}_domain`
    }
  }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.createCollectionCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCollectionEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.postMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return this.createCollectionCallID;
  }
  handleIconClick = (event: any, galleryData: any) => {
    this.setState({ anchorEl: event.currentTarget, collectionID: galleryData.id, galleryData:galleryData})
  }
  deleteCollectionApi = async() => {
    this.setState({anchorEl:null, showDeleteDialog:false})
    const token = await getStorageData("authContributorToken");
    const header = {
      token: token
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteCollectionCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.collectionEndPoint(this.state.collectionID)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.deleteMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return this.deleteCollectionCallID;
  }
  setDeleteCollectionData = (response:any) => {
    this.setState({
      showAlert:true,alertType:"delete",
      userSuccessAlert:"Successfully Deleted"
    })
    this.props.showCollectionApi()
  }
  setCreateCollectionApi = (response:any) => {
    if(!response.message){
      this.setState({
        showAlert:true,alertType:"success",
        userSuccessAlert:"Collection Created Successfully",
        collectionName:"",
        setVisibility:"Public",
         selectedButton: "image",
      })
      this.props.showCollectionApi()
    }
  }
  handleAlertClose = () => {
    this.setState({showAlert:false})
  } 
  changeStatusApi = async() => {
    const token = await getStorageData("authContributorToken");
    const header = {
      token: token,
      "Content-Type": "application/json"
    };
    const httpBody = {
      "collection":
      {
          "collection_subtype": this.state.galleryData.attributes.collection_subtype == 'public_domain' ? 'private_domain' : 'public_domain'
      }
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.changeStatusCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.collectionEndPoint(this.state.collectionID)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.putMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return this.changeStatusCallID;
  }
  setChangeStatusApi = (response:any) => {
    this.setState({
      showAlert:true,alertType:"success",
      userSuccessAlert:"Collection Successfully Updated",
      anchorEl:null
    })
    this.props.showCollectionApi()
  }
  handleEdit = () => {
    this.setState({ editing: true });
  };

  handleSave = () => {
    this.setState({ editing: false });
  };

  handleChange = (event: any) => {
    this.setState({ collectionName: event.value });
  };
  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleSave();
      this.changeCollectioNameApi()
    }
  };
  changeCollectioNameApi = async() => {
    const token = await getStorageData("authContributorToken");
    const header = {
      token: token,
      "Content-Type": "application/json"
    };
    const httpBody = {
      "collection":
      {
          "collection_name": this.state.collectionName
      }
    }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.changeNameCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.collectionEndPoint(this.state.collectionID)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.putMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return this.changeNameCallID;
  }
  setChangeNameApi= (response:any) => {
    this.setState({
      showAlert:true,alertType:"success",
      userSuccessAlert:"Collection Name Successfully Updated",
      anchorEl:null
    })
    this.props.showCollectionApi()
  }
  getIconLeftStyle = (webStyle:any) => {
    const {currentPage} = this.props;
    if (currentPage === 1) {
      return webStyle.disabled;
    } else {
      return webStyle.able;
    }
  };
  getIconRightStyle = (webStyle:any) => {
    const {currentPage,totalCount} = this.props;

    if (currentPage == totalCount?.total_pages) {
      return webStyle.disabled;
    } else {
      return webStyle.able;
    }
  };
  showAlertType = () => {
    const {alertType} = this.state;
    if (alertType ===  "success"){
      return "success"
    }
    else if(alertType ===  "delete"){
      return 'delete'
    }
    else{
      return 'error'
    }
  }
  deleteMdal = () => {
    this.setState({showDeleteDialog:true})
  }
  handleCloseDeleteDialog = () => {
    this.setState({showDeleteDialog:false})
  }
  // Customizable Area End
}
