import React, { CSSProperties } from "react";
// Customizable Area Start
import { Grid, Box, styled, Radio, RadioGroup, FormControlLabel, FormControl} from "@material-ui/core";
const images = require("./assets");
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import UserHiresDownloadController, { Props } from './UserHiresDownloadController.web';
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
import Input from "../../../components/src/DesignSystem/Input/Input.web";
// Customizable Area End

// Customizable Area Start
interface WebStyle {
    [key: string]: CSSProperties;
}
// Customizable Area End

export default class UserHires extends UserHiresDownloadController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const setArray = ["1", "2", "3"];
        const { filterValue } = this.state;
        return (
            <>
                <Grid style={webStyle.showAllDownload}>
                    <Grid style={webStyle.showSearchBar}>
                        <FormControl component="fieldset">
                            <RadioGroup value={filterValue} data-test-id="handleFilterChange" onChange={this.handleFilterChange} style={webStyle.radioStyle}>
                                <CustomRadio value="All" control={<Radio style={{color: this.setColor("All")}} />} label="All" style={{
                                    color: this.setColor("All"), ...webStyle.selectedvalue
                                }} />
                                <CustomRadio value="Requested" control={<Radio style={{color: this.setColor("Requested")}} />} label="Requested" style={{
                                    color: this.setColor("Requested"), ...webStyle.selectedvalue
                                }} />
                                <CustomRadio value="Upcoming" control={<Radio style={{color: this.setColor("Upcoming")}} />} label="Upcoming" style={{
                                    color: this.setColor("Upcoming"), ...webStyle.selectedvalue
                                }} />
                                <CustomRadio value="Past Hires" control={<Radio style={{color: this.setColor("Past Hires")}} />} label="Past Hires" style={{
                                    color: this.setColor("Past Hires"), ...webStyle.selectedvalue
                                }} />

                            </RadioGroup>
                        </FormControl>
                        <StyledBox>
                            <Input
                                data-test-id="search_data"
                                placeholder="Search Contributors"
                                isSearch
                                type='text'
                                startAdornment={<img src={images.search} alt="Search" />}
                            />
                        </StyledBox>
                    </Grid>
                    {setArray.map((data: string, index: number) => {
                        return (
                            <Grid
                                style={webStyle.dowloadPreviewData}
                                key={index}
                                data-test-id="downloadData"
                            >
                                <Grid style={webStyle.detailedShow}>
                                    <img src={images.Flower} style={webStyle.profileLogo} />
                                    <Grid style={webStyle.contentDetails}>
                                        <CustomTypography variant="h7">
                                            david hebrews
                                        </CustomTypography>
                                        <CustomTypography variant="secondary" component="body14">
                                            New York, USA
                                        </CustomTypography>
                                        <Box style={webStyle.actionBtn}>
                                            <CustomButton
                                                startIcon={<img src={images.clock} style={webStyle.icons} />}
                                                variant="actionBtns" fullWidth="fullWidth" size={"hiresDetailsBtn"}
                                            >Request Sent</CustomButton>
                                        </Box>
                                        <Grid style={webStyle.detailedBtn}>
                                            <Grid style={webStyle.showDetailsBtn}>
                                                <CustomButton
                                                    variant={"withdrawBtn"}
                                                    size={"hiresDetailsBtn"}
                                                    startIcon={<img src={images.callender} />}
                                                >
                                                    23/10/2023
                                                </CustomButton>
                                            </Grid>
                                            <Grid style={webStyle.showDetailsBtn}>
                                                <CustomButton
                                                    variant={"withdrawBtn"}
                                                    size={"hiresDetailsBtn"}
                                                    startIcon={<img src={images.clock} />}
                                                >
                                                    10 hours
                                                </CustomButton>
                                            </Grid>
                                            <Grid style={webStyle.showDetailsBtn}>
                                                <CustomButton
                                                    variant={"withdrawBtn"}
                                                    size={"hiresDetailsBtn"}
                                                    startIcon={<img src={images.location} />}
                                                >
                                                    London
                                                </CustomButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid style={webStyle.downloadPreviewSection}>
                                    <CustomButton
                                        variant={"withdrawBtn"}
                                        size={"smallBtn"}
                                    >
                                        Withdraw request
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
const webStyle: WebStyle = {
    dowloadPreviewData: {
        display: "flex",
        justifyContent: "space-between",
        width: "95%",
        padding: "20px",
        height: "100%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
    },
    showSearchBar: {
        justifyContent: "space-between",
        display: "flex",
        padding: "0px 20px 20px 50px"
    },
    detailedBtn: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px"
    },
    contentDetails: {
        display: "flex",
        flexDirection: "column",
        gap: '10px'
    },
    downloadPreviewSection: {
        display: "flex",
        alignItems: "center",
        paddingRight: "50px",
    },
    detailedShow: {
        display: "flex",
        gap: "30px",
        paddingLeft: "50px"
    },
    showAllDownload: {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    cursor: {
        cursor: 'pointer'
    },
    profileLogo: {
        borderRadius: "50%",
        width: "122px",
        height: "122px"
    },
    actionBtn: {
        width: '128px',
        height: "30px",
    },
    showDetailsBtn: {
        height: "34px",
        width: "135px",
    },
    icons: {
        color: "#D97706"
    },
    radioColor: {
        color: "black"
    },
    radioStyle: {
        display: "flex",
        flexDirection: "row"
    },
    selectedvalue:{
        fontFamily:"Outfit",
        fontSize:"16px",
        fontWeight:400,
        lineHeight:"20px"
    }
};
const StyledBox = styled(Box)({
    "& .MuiOutlinedInput-root": {
        fontFamily: "Outfit",
        fontSize: "14px !important",
        fontWeight: 500,
        color: `${customTheme.palette.secondary.main} !important`,
        padding: "14px 16px",
        height: "48px",
        width: "394px",
        backgroundColor: customTheme.palette.white.light,
        border: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "0px"
    }
});
const CustomRadio = styled(FormControlLabel)({
    "& .MuiTypography-body1":{
        fontFamily:"Outfit !important",
        fontSize:"16px !important",
        fontWeight:400,
        lineHeight:"20px !important"
    }
})
// Customizable Area End
