import React from "react";
// Customizable Area Start
import { Box, Drawer, styled } from "@material-ui/core";
import customTheme from "../../../src/DesignSystem/Theme/Theme.web";
const webStyle: any = {
  SideBarMain: {
    display: "flex",
    height: "calc(100vh - 72px)",
    overflow: "hidden",
  },
  SideBarWrapper: {
    width: "calc(130px - 40px)",
    height: "calc(100vh - 72px)",
    paddingLeft: "40px",
  },
  SideBarItemBlock: {
    display: "flex",
    padding: "16px 0px 9px 8px",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    borderRight: "1px solid #C4C4C4",
  },
  listWrapper: {
    height: "60%",
    paddingRight: "8px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  listInner: {
    padding: "4px",
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  listIcon: {
    width: "24px",
    height: "24px",
    marginBottom: "8px",
  },
  activeStyle: {
    borderLeft: "3px solid #000000",
    color: "#000000",
  },
  ListIconBottomBlock: {
    margin: "16px 8px 35px 8px",
    paddingTop: "16px",
    borderTop: "1px solid #C4C4C4",
  },
  RightSideContent: {
    overflow: "scroll",
    width: "100%",
    overflowX: "hidden",
  },
  listGrey: {
    backgroundColor: customTheme.palette.grey.primary,
  },
  listWhite: {
    backgroundColor: customTheme.palette.white.main,
  },
};
import CustomTypography from "../CustomTypography/CustomTypography.web";
import NavigationMenuController, {
  Props,
  earningMenuList,
} from "../../../../blocks/navigationmenu/src/NavigationMenuController";
import ClearIcon from "@material-ui/icons/Clear";
// Customizable Area End

export default class SideBar extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { sideBarListItem, sideBarBottomNav } = this.props;
    const { active } = this.state;
    return (
      <StyledBox>
        <Box style={webStyle.SideBarMain}>
          <Box style={webStyle.SideBarWrapper}>
            <Box style={webStyle.SideBarItemBlock}>
              <Box style={webStyle.listWrapper} className="listBox">
                {sideBarListItem &&
                  sideBarListItem.map((item) => {
                    return (
                      <Box
                        data-test-id={`click-${item.id}`}
                        key={item.id}
                        style={
                          active === item.id
                            ? { ...webStyle.activeStyle, ...webStyle.listInner }
                            : webStyle.listInner
                        }
                        onClick={() =>
                          this.handleListItemClick(item.id, item.PathUrl)
                        }
                      >
                        <img
                          src={active === item.id ? item.ActiveIcon : item.icon}
                          style={webStyle.listIcon}
                        />
                        <CustomTypography
                          variant={active === item.id ? "primary" : "secondary"}
                          component={active === item.id ? "body14": "body1100" }
                        >
                          {item.listTitle}
                        </CustomTypography>
                      </Box>
                    );
                  })}
              </Box>
              <Box style={webStyle.ListIconBottomBlock}>
                {sideBarBottomNav &&
                  sideBarBottomNav.map((item) => {
                    return (
                      <Box
                        data-test-id={`click-${item.id}`}
                        key={item.id}
                        style={
                          active === item.id
                            ? { ...webStyle.activeStyle, ...webStyle.listInner }
                            : webStyle.listInner
                        }
                        onClick={() =>
                          this.handleListItemClick(item.id, item.PathUrl)
                        }
                      >
                        <img
                          src={active === item.id ? item.ActiveIcon : item.icon}
                          style={webStyle.listIcon}
                        />
                        <CustomTypography
                          variant={active === item.id ? "primary" : "secondary"}
                          component={active === item.id ? "body14": "body1100" }
                        >
                          {item.listTitle}
                        </CustomTypography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.RightSideContent}>{this.props.children}</Box>
        </Box>
        <StyleDrawer open={this.state.openDrawer} onClose={this.handleDrawer}>
          <EarningsMenuHeading>
            <CustomTypography component="body16">Earnings</CustomTypography>
            <div onClick={this.handleDrawer}>
              <ClearIcon />
            </div>
          </EarningsMenuHeading>
          {earningMenuList &&
            earningMenuList.map((item: any) => {
              return (
                <MainBox
                  style={
                    this.state.activeList === item.id
                      ? webStyle.listGrey
                      : webStyle.listWhite
                  }
                  onClick={() => this.handleListMenu(item.id)}
                >
                  <EarningsMenuList
                    onClick={() =>
                      this.handleListItemClick(item.id, item.PathUrl,true)
                    }
                  >
                    <Icon>
                      <img src={item.icon} alt="icon" />
                    </Icon>
                    <CustomTypography component="body11">
                      {item.listTitle}
                    </CustomTypography>
                  </EarningsMenuList>
                </MainBox>
              );
            })}
        </StyleDrawer>
      </StyledBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyleDrawer = styled(Drawer)({
  "&.MuiDrawer-root ": {
    top: "72px !important",
    left: "127px !important",
  },
  "& .MuiBackdrop-root": {
    top: 72,
    left: 127,
  },
  "& .MuiDrawer-paperAnchorLeft": {
    left: 126,
    right: "auto",
    top: 72,
    minWidth: 264,
  },
  "& .MuiPaper-elevation16": {
    boxShadow: "none",
  },
});

const MainBox = styled("div")({
  width: "248px",
  height: "48px",
  marginLeft: 15,
});

const EarningsMenuHeading = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  margin: "15px",
});

const EarningsMenuList = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "10px 10px 10px 25px",
  cursor:"pointer"
});

const Icon = styled("div")({
  paddingRight: "8px",
});
const StyledBox = styled(Box)({
  "& .listBox": {
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});

// Customizable Area End
