import React from "react";

// Customizable Area Start
import {
  Grid, styled
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import PhotoLibraryCollection from './PhotoLibraryCollection.web'
import PhotoLibraryGallery from "./PhotoLibraryGallery.web";
import PortfolioCollectionHeader from "./PortfolioCollectionHeader.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import {sideBarContributorListItem, sideBarContributoBottomNav } from "../../../blocks/navigationmenu/src/NavigationMenuController";

// Customizable Area End

import PhotoLibraryController, {
  Props,
  configJSON
} from "./PhotoLibraryController";

// Customizable Area Start
// Customizable Area End

export default class PhotoLibrary extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {collectionModal,showCollection,searchValue,selectedCollectionContent, totalCount,currentPage,
      collectionData, selectedPublish,selectedUpdate,selectedCollection, contentModals,selectedContent,
    currentDataPage, showSearchError} = this.state

    return (
      <MainBox>
          <NavigationMenu navigation={undefined} id={""} contributorLogin={true} goToCatalogue={this.goToCatalogue} data-test-id="showCollection" showCollection={showCollection} onItemClick={this.handleItemClick} goToLanding={this.goToLanding}/>
          <SideBar id="" navigation={this.props.navigation} sideBarBottomNav={sideBarContributoBottomNav} sideBarListItem={sideBarContributorListItem} onItemClick={this.handleItemClick}>
          {!showCollection ? 
          <Grid style={webStyle.mainGrid}> <Grid style={webStyle.collectionConatiner}>
             <PhotoLibraryCollection
                collectionModal={collectionModal}
                data-test-id="PhotoLibraryCollection"
                closeCollectionModal={this.closeCollectionModal}
                openCollectionModal={this.openCollectionModal}
                collectionData={collectionData}
                showCollectionData={this.showCollectionData}
                showCollectionApi={this.showCollectionApi}
                selectedContent={selectedContent}
                handleContentSelect={(e:any) => this.handleContentSelect(e)}
                handlePublishSelect={this.handlePublishSelect}
                handleSortSelect={this.handleSortSelect}
                selectedPublish={selectedPublish}
                selectedUpdate={selectedUpdate}
                searchValue={searchValue}
                handleSearchValue={this.handleSearchValue}
                />
            </Grid>
            <Grid style={webStyle.sectionContainer}>
             <PhotoLibraryGallery
              collectionModal={collectionModal}
              closeCollectionModal={this.closeCollectionModal}
              data-test-id="PhotoLibraryGallery"
              openCollectionModal={this.openCollectionModal}
               showCollectionData={(data:any) => this.showCollectionData(data)}
              collectionData={collectionData}  
              showCollectionApi={this.showCollectionApi}
              handleContentSelect={(e:any) => this.handleContentSelect(e)}
              handlePublishSelect={this.handlePublishSelect}
              handleSortSelect={this.handleSortSelect}
              prevPage={this.prevPage}
              nextPage={this.nextPage}
              currentPage={currentPage}
              totalCount={totalCount}
              selectedContent={selectedContent}
              showSearchError={showSearchError}
              />
            </Grid>
            </Grid> :
            <Grid>
              <Grid style={webStyle.collectionSelectConatiner}>
             <PortfolioCollectionHeader  selectedCollection={selectedCollection}
             contentModals={contentModals}
             openContentModal={this.openContentModal}
             closeContentModal={this.closeContentModal}
             data-test-id="PortfolioCollectionHeader"
             selectedCollectionContent={selectedCollectionContent}
             showSelectedCollectionApi={this.showSelectedCollectionApi}
             handleSortSelect={this.handleSortSelect}
             selectedUpdate={selectedUpdate}
             currentDataPage={currentDataPage}
             prevDataPage={this.prevDataPage}
              nextDataPage={this.nextDataPage}
              onItemClick={this.handleItemClick}
              collectionData={collectionData}
             />
            </Grid>
            </Grid>
            }
            <Footer userType={"contributor"} 
                        navigation={""} />
            </SideBar>
      </MainBox>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  collectionConatiner:{
    width:"100%",
    maxHeight:"200px",
    height:"auto"
  },
  sectionContainer:{
  height:"auto",
  maxHeight:"100%",
  },
galleryContainer:{
  height:"550px",
},
  collectionSelectConatiner:{
    width:"100%",
    height:"auto"
  },
  mainGrid:{overflow:"hidden"}
};
const MainBox = styled(Grid)({
  overflowX:"hidden"

})
// Customizable Area End
