// Customizable Area Start
import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Formik } from "formik";
import * as Yup from "yup";

import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import {
  sideBarContributoBottomNav,
  sideBarContributorListItem,
} from "../../../blocks/navigationmenu/src/NavigationMenuController";
import CustomButton from "../../../components/src/DesignSystem/CustomButton/CustomButton.web";
import CustomDivider from "../../../components/src/DesignSystem/Divider/Divider.web";
import CustomDropDown from "../../../components/src/DesignSystem/CustomDropDown/CustomDropDown.web";
import CustomTypography from "../../../components/src/DesignSystem/CustomTypography/CustomTypography.web";
import Footer from "../../../components/src/DesignSystem/Footer/Footer.web";
import SideBar from "../../../components/src/DesignSystem/SideBar/SideBar.web";
import TaxCenterController from "./TaxCenterController.web";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";

export const configJSON = require("./config");

// Customizable Area End
export class TaxCenter extends TaxCenterController {
  render() {
    // Customizable Area Start

    const { classes } = this.props;

    return (
      <>
        <NavigationMenu
          navigation={this.props.navigation}
          id={""}
          contributorLogin={true}
          data-test-id="showCollection"
        />

        <SideBar
          id=""
          navigation={this.props.navigation}
          sideBarBottomNav={sideBarContributoBottomNav}
          sideBarListItem={sideBarContributorListItem}
        >
          <Box className={classes.taxCenterContainer}>
            <Box className={classes.taxCenterContent}>
              <CustomTypography component="h7">TAX CENTER</CustomTypography>

              <Box className={classes.taxCenterContentRow}>
                <CustomTypography component="body1111">
                  Before we can pay you, Qstock needs to have your correct tax
                  form on file.
                </CustomTypography>
              </Box>

              <Box className={classes.taxCenterContentRow}>
                <CustomTypography component="body1111">
                  While we can’t give you tax or legal advice, we’ve created the
                  following questions to help you choose the best tax form and
                  make your own decision about how to comply with applicable
                  U.S. tax laws. If you still have questions after reviewing the
                  information we’ve provided, please contact your legal and/or
                  tax advisor.
                </CustomTypography>
              </Box>

              <Box className={classes.taxCenterContentRow}>
                <CustomTypography component="body1111">
                  Please answer the following questions to help us determine
                  which tax form is appropriate for your situation:
                </CustomTypography>
              </Box>
            </Box>

            <Box className={classes.taxCenterForm}>
              <Formik
                initialValues={{
                  isUSPerson: null,
                  isContribute: null,
                }}
                validationSchema={Yup.object().shape({
                  isUSPerson: Yup.boolean()
                    .nullable()
                    .required("Please select an option"),
                  isContribute: Yup.boolean()
                    .nullable()
                    .required("Please select an option"),
                })}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  console.log("onSubmit", values, actions);
                }}
                data-test-id="tax-center-form"
              >
                {({
                  values,
                  errors,
                  touched,

                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className={classes.taxCenterFormInner}>
                        <Box className={classes.taxCenterFormRow}>
                          <Box className={classes.taxCenterFormLabel}>
                            <CustomTypography component="body1111">
                              For U.S. tax purposes, are you a U.S. person?
                            </CustomTypography>
                          </Box>

                          <FormControl
                            className={classes.taxCenterFormControl}
                            error={Boolean(
                              touched.isUSPerson && errors.isUSPerson
                            )}
                          >
                            <Box className={classes.taxCenterDropdown}>
                              <CustomDropDown
                                data-test-id="is-us-person-dropdown"
                                variant="dropdownContent"
                                endAdornment={<ExpandMore />}
                                options={configJSON.yesNoOptions}
                                selectedItem={this.getSelectedOption(
                                  values.isUSPerson
                                )}
                                selectNewItem={(option) => {
                                  setFieldValue("isUSPerson", option.value);
                                }}
                              />
                            </Box>

                            {Boolean(
                              touched.isUSPerson && errors.isUSPerson
                            ) && (
                              <FormHelperText>
                                {errors.isUSPerson}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>

                        <CustomDivider
                          orientation="vertical"
                          variant="greyPrimary"
                        />

                        <Box className={classes.taxCenterFormRow}>
                          <Box className={classes.taxCenterFormLabel}>
                            <CustomTypography component="body1111">
                              Are you contributing to Shutterstock as an
                              individual or as a business?
                            </CustomTypography>
                          </Box>

                          <FormControl
                            className={classes.taxCenterFormControl}
                            error={Boolean(
                              touched.isContribute && errors.isContribute
                            )}
                          >
                            <Box className={classes.taxCenterDropdown}>
                              <CustomDropDown
                                data-test-id="is-contribute-dropdown"
                                variant="dropdownContent"
                                endAdornment={<ExpandMore />}
                                options={configJSON.yesNoOptions}
                                selectedItem={this.getSelectedOption(
                                  values.isContribute
                                )}
                                selectNewItem={(option) => {
                                  setFieldValue("isContribute", option.value);
                                }}
                              />
                            </Box>

                            {Boolean(
                              touched.isContribute && errors.isContribute
                            ) && (
                              <FormHelperText>
                                {errors.isContribute}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box className={classes.taxCenterSubmit}>
                        <CustomButton
                          data-test-id="submit-btn"
                          size="large"
                          type="submit"
                        >
                          SUBMIT
                        </CustomButton>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Box>

          <Footer userType={"contributor"} navigation={""} />
        </SideBar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  taxCenterContainer: {
    padding: "28px 16px",
  },

  taxCenterContent: {
    maxWidth: 934,
    padding: 8,
  },

  taxCenterContentRow: {
    marginTop: 16,
  },

  taxCenterForm: {
    marginTop: 20,
  },

  taxCenterFormInner: {
    border: `1px solid ${customTheme.palette.secondary.light}`,
    padding: 12,
  },

  taxCenterFormRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 20,
  },

  taxCenterFormControl: {
    maxWidth: 152,
    width: "100%",
  },

  taxCenterFormDivider: {
    margin: "28px 0",
  },

  taxCenterFormLabel: {
    display: "flex",
    alignItems: "center",
    maxWidth: 487,
    width: "100%",
  },

  taxCenterDropdown: {
    maxWidth: 152,

    "& .dropdownCss": {
      textTransform: "capitalize !important",
      justifyContent: "space-between !important",
    },

    "& > div": {
      border: `1px solid ${customTheme.palette.primary.main}`,
      backgroundColor: customTheme.palette.white.main,
      padding: "11px 16px",
      boxSizing: "border-box",

      "& .label": {
        fontSize: "14px !important",
      },
    },
  },

  taxCenterSubmit: {
    marginTop: 32,
    display: "flex",
    justifyContent: "flex-end",

    "& button": {
      background: `${customTheme.palette.secondary.main} !important`,
      width: 159,
      border: "none !important",
    },
  },
});
// Customizable Area End

export default withStyles(styles)(TaxCenter);
