import React from "react";
// Customizable Area Start
import { Typography, Box, styled, Grid } from "@material-ui/core";
// Customizable Area End

import ContentManagementController, { Props } from "./LandingPageController";
// Customizable Area Start
import { profileIcon } from "./assets";
import customTheme from "../../../components/src/DesignSystem/Theme/Theme.web";
// Customizable Area End

export default class FeaturedPhotographer extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <TrendingBox>
        {this.state.featuredData && (
          <>
            <FeaturesTitleBox>
              <Title1>FEATURED PHOTOGRAPHERS</Title1>
              <Title2>VIEW ALL</Title2>
            </FeaturesTitleBox>
            <FeaturesContainer>
              <Grid container spacing={2}>
                {this.state.featuredData
                  ?.slice(0, 3)
                  .map((item: any, index: any) => (
                    <Grid key={index} item xs={12} sm={12} md={4}>
                      <FeaturesSubContainer key={index}>
                        <TrendingVideo
                          src={item.attributes.image}
                          alt="image"
                        />
                        <FeaturesImg>
                          <ProfileImg src={item.attributes.profile_photo} />
                          <FeaturesText>
                            <FeaturesSubText1>
                              {item?.attributes?.name}
                            </FeaturesSubText1>
                            <FeaturesSubText2>
                              {item?.attributes?.location}
                            </FeaturesSubText2>
                          </FeaturesText>
                          <ProfileIcon src={profileIcon} />
                        </FeaturesImg>
                      </FeaturesSubContainer>
                    </Grid>
                  ))}
              </Grid>
            </FeaturesContainer>
          </>
        )}
      </TrendingBox>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const TrendingBox = styled(Box)({
  marginBottom: "164px",
});

const TrendingVideo = styled("img")({
  width: "100%",
  height: 284,
});

const FeaturesImg = styled("div")({
  position: "absolute",
  bottom: "15px",
  left: "0",
  color: "#fff",
  fontSize: "12px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  width: "100%",
});

const FeaturesText = styled("div")({
  fontSize: 10,
  fontWeight: 400,
  margin: 0,
  paddingLeft: 12,
});

const FeaturesSubText1 = styled("p")({
  fontSize: 12,
  fontWeight: 500,
  margin: 0,
  paddingBottom: 4,
  fontFamily:"LemonMilk",
  letterSpacing:"1.5px"
});

const FeaturesSubText2 = styled("p")({
  fontSize: 10,
  fontWeight: 400,
  margin: 0,
  fontFamily:"LemonMilk",
  letterSpacing:"1.5px"
});

const FeaturesTitleBox = styled(Box)({
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  justifyContent: "space-between",
  marginTop: "264px",
});

const Title1 = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  fontFamily:"LemonMilk",
  letterSpacing:"1px"
});

const Title2 = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  lineHeight:"18px",
  borderBottom:`1.5px solid ${customTheme.palette.primary.main}`,
  fontFamily:"LemonMilk"
});

const ProfileImg = styled("img")({
  height: 50,
  width: 50,
  borderRadius: "50%",
  paddingLeft: 15,
});

const ProfileIcon = styled("img")({
  position: "absolute",
  right: 20,
  backgroundColor: "rgba(128, 128, 128, 0.30)",
  padding: "10px",
});

const FeaturesContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: 10,
  marginTop: 24,
  flexWrap: "wrap",
  "@media(max-width: 1422px)": {
    justifyContent: "center",
  },
});

const FeaturesSubContainer = styled("div")({
  marginBottom: "16px",
  position: "relative",
});
// Customizable Area End
