import React from 'react';
import CustomTheme from '../../DesignSystem/Theme/Theme.web';
import customTheme from '../../DesignSystem/Theme/Theme.web';


const Divider = (props: any) => {

    const dividerStyles: any = {
        ...styles[props.variant],
        ...styles[props.margin]
    };

    return (
        <div style={dividerStyles}></div>
    )
}

const styles: any = {
    primary: {
        backgroundColor: CustomTheme.palette.primary.main,
        height: '1px',
        minHeight: '1px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    secondary: {
        backgroundColor: CustomTheme.palette.secondary.main,
        height: '1px',
        minHeight: '1px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    white: {
        backgroundColor: CustomTheme.palette.white.main,
        height: '1px',
        minHeight: '1px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    whiteDark: {
        backgroundColor: CustomTheme.palette.white.dark,
        height: '1px',
        minHeight: '1px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    grey: {
        backgroundColor: CustomTheme.palette.grey.secondary,
        height: '1px',
        minHeight: '1px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    greyPrimary: {
        backgroundColor: CustomTheme.palette.secondary.light,
        height: '1px',
        minHeight: '1px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    noMargin:{
        margin:0
    },
    vertical: {
        backgroundColor:customTheme.palette.secondary.main,
        height: '20px',
        width: '1px',
        minWidth: '1px',
        margin: '10px 0',
    }
}

export default Divider;