Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.watermarkPostAPiMethod = "POST";
exports.watermarkApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Watermark";
exports.labelBodyText = "Watermark Body";
exports.watermarkImageCondition="Upload your watermark with no background "
exports.selectFiles="Choose file";
exports.dragContent="Drag and drop your files here";
exports.or="or"
exports.retryBtn= "Retry"
exports.errorContent = "Your upload failed because it is in wrong format."
exports.btnExampleTitle = "CLICK ME";
exports.postWaterMarkImageEndPoint="bx_block_watermark/watermark_makers"
// Customizable Area End