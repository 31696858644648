Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";
exports.postMethodContentType = "application/json"
exports.postMethod= 'POST'
exports.addQueryContactApi = "bx_block_contact_us/contacts"
exports.btnExampleTitle = "CLICK ME";
exports.emailRegEx = `[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$`;

exports.contactUs="CONTACT US"
exports.sendMsg="SEND MESSAGE"
exports.callUs="Call Us"
exports.callUs1="+1 800-805-6802"
exports.emailUs="Email Us"
exports.emailUs1="support@qstock.com"
exports.address="Address"
exports.address1="9555 Harmon Rd,Fort Worth, TX 76177"
exports.talk="TALK TO US"
exports.talk1="Fill in the form below, and we will get back to you as soon as possible!"
exports.contactNum="Contact number"
exports.details="Details"
exports.subject="What do you want to talk about?"
exports.about="Any details you would like to share?"
exports.social="Social Media"
exports.nameValidation=/^$|^[A-Za-z\s]+$/;
exports.phoneNumberError="Please enter a valid number"
exports.requestTitle="Your inquiry has been successfully sent!"
exports.requestSubTitle="Our team will reach you  out in shortly."

exports.ticketTitle="LIST OF TICKETS"
exports.ticketTabAll="All"
exports.ticketTabPending="Pending"
exports.ticketTabSolved="Solved"
exports.ticketTabReject="Reject"
exports.searchPlaceholder="Search tickets"
exports.sortBy = [
  {label:"Sort By",value:"sortBy"},
  {label:"Newest First",value:"newest"},
  {label:"Oldest First",value:"oldest"},
]

exports.idLabelText ="Id"
exports.subjectLabelText = "Subject" 
exports.requesterLabelText = "Requester"
exports.requestedLabelText = "Requested"
exports.emailLabelText = "Email"
exports.viewTicketLabel = "View Ticket"
exports.deleteTicketLabel = "Delete"
exports.deleteDialogTitle = "Are you sure?"
exports.deleteDialogDescription = "You are about to delete a ticket, Please confirm"
exports.cancelBtnLabel = "Cancel"
exports.deleteBtnLabel = "Yes, I confirm"
exports.commentLabel = "Latest Comments"

exports.contentApiContentType = "application/json";
exports.apiContentTypeFormData = "multipart/form-data";
exports.statusUpdateApiEndpoint = "bx_block_contact_us/contacts/update_status"
exports.apiMethodTypeGet = "GET"
exports.apiMethodTypePost = "POST"
exports.apiMethodTypeDelete = "DELETE"
exports.apiMethodTypePut = "PUT"
exports.apiMethodTypePatch = "PATCH"
exports.ticketApiEndPoint = "bx_block_contact_us/contacts";
// Customizable Area End