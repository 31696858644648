export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const myreferralsbanner = require("../assets/myreferralsbanner.png");
export const facebook = require("../assets/facebook.png");
export const facebookgrey = require("../assets/facebookgrey.png");
export const twitter = require("../assets/twitter.png");
export const twittergrey = require("../assets/twittergrey.png");
export const linkdin = require("../assets/linkdin.png");
export const linkdingrey = require("../assets/linkdingrey.png");
export const upload = require("../assets/view_vector (11).png")
export const doubleTickIcon = require("../assets/view_vector (12).png")