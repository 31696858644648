Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "paymentadmin2";
exports.labelBodyText = "paymentadmin2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.handingContributorData = [
  {
    id: 1,
    title: "Display name",
  },
  {
    id: 2,
    title: "Referral date",
  },
  {
    id: 3,
    title: "Approved items",
  },
  {
    id: 4,
    title: "",
  },
];

exports.handingCustomerData = [
  {
    id: 1,
    title: "Post date",
  },
  {
    id: 2,
    title: "Purchase date ",
  },
  {
    id: 3,
    title: "Status",
  },
  {
    id: 4,
    title: "Referral earnings",
  },
  {
    id: 5,
    title: "",
  },
];
exports.month = [{label:"October",value:"October"}]
exports.year = [{label:"2023",value:"2023"}]
exports.activity=[{label:"Active", value:"Active"}]
exports.sorting=[{label:"Sort By", value:"Sort By"}]
exports.yesNoOptions =[
  {
    label: "Select",
    value: null,
  },

  {
    label: "Yes",
    value: true,
  },

  {
    label: "No",
    value: false,
  },
]
// Customizable Area End